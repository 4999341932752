import React from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import toufiq from "../img/resources/toufiq1.png";
import bgcloud from "../img/resources/designback.png";
import threeD from "../img/design/threeD.png";
import motion from "../img/design/motion.png";
import motiond from "../img/design/motiond.png";
import design from "../img/design/design.png";
import ui from "../img/design/ui.png";
import uid from "../img/design/uid.png";
import designfoot from "../img/design/tecmetrixd.png";
const Design = () => {
  return (
    <>
      <Nav />

      <div className="container-fluid client__s">
        <img src={bgcloud} alt="" className="imgWatermark" />
        <section className="container-md text-start mb-5 px-lg-5 px-md-5 px-0">
          <div className="servicesCover">
            <h1 className="mb-4 mt-4">Professional Design</h1>

            <div className="servicesText">
              <h3 className="mb-2">Exploring Creative Visualization</h3>
              <p className="servicesSubText">
                First impression is very important, and a unique design creates
                good impression. Our creative designers are always committed to
                provide you premium and Professional Design. We follow the
                design process to solve each problem in an innovative way.
              </p>
            </div>
          </div>
        </section>
        {/*  */}
        <div className="container px-lg-5 px-md-5 px-0 pb-5 mb-5">
          <div className="row">
            <div className="col-lg-4 pb-lg-0 pb-4">
              <div className="card box_shadow_ ">
                <div className="position-relative">
                  <div className="position-absolute top-50 bottom-50 pt-5 mt-5 ps-4 translate-middle-y start-0 ">
                    <div className="bg-white p-2 bgicon">
                      {" "}
                      <img alt="..." className="serviceIcons" src={threeD} />
                    </div>
                  </div>
                </div>
                <div className="">
                  <img
                    src={design}
                    className="p-0 m-0 card-img cardWidthF rounded-bottom-0"
                    alt="..."
                  />
                </div>

                <div className="card-body p-3 mb-4">
                  <h1 className="fs-5 fw-semibold mt-4">
                    2D & 3D Product Design
                  </h1>
                  <p className="card-text textjustify">
                    Bring your innovative thoughts to the form of products. We
                    have the best solution in an innovative way to design your
                    thoughts.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pb-lg-0 pb-4">
              <div className="card box_shadow_ ">
                <div className="position-relative">
                  <div className="position-absolute top-50 bottom-50 pt-5 mt-5 ps-4 translate-middle-y start-0 ">
                    <div className="bg-white p-2 bgicon">
                      {" "}
                      <img alt="..." className="serviceIcons" src={ui} />
                    </div>
                  </div>
                </div>
                <div className="">
                  <img
                    src={uid}
                    className="p-0 m-0 card-img cardWidthF rounded-bottom-0"
                    alt="..."
                  />
                </div>

                <div className="card-body p-3 mb-4">
                  <h1 className="fs-5 fw-semibold mt-4">
                    UI/UX Design & Prototyping
                  </h1>
                  <p className="card-text textjustify">
                    From concept to reality with great UI/UX design will enable
                    the right look and feel of your websites and mobile
                    applications.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card box_shadow_ ">
                <div className="position-relative">
                  <div className="position-absolute top-50 bottom-50 pt-5 mt-5 ps-4 translate-middle-y start-0 ">
                    <div className="bg-white p-2 bgicon">
                      {" "}
                      <img alt="..." className="serviceIcons" src={motion} />
                    </div>
                  </div>
                </div>
                <div className="">
                  <img
                    src={motiond}
                    className="p-0 m-0 card-img cardWidthF rounded-bottom-0"
                    alt="..."
                  />
                </div>

                <div className="card-body p-3 mb-4">
                  <h1 className="fs-5 fw-semibold mt-4">
                    Motion Graphic & Animation{" "}
                  </h1>
                  <p className="card-text textjustify">
                    Advancing the human experience through creative and premium
                    visual experiences with motion graphic and animation
                    service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
      </div>

      <div className="container-lg mb-5 mt-3 pt-5">
        <div className="row helpcard justify-content-center mx-auto">
          <div className="supportCard row p-0">
            <div className="col-lg-2 col-5">
              <img
                src={toufiq}
                alt="Sales | mPair Technologies"
                className="cloud_image"
              />
            </div>
            <div className="col-lg-7 col-7 ">
              <div className="support-contact-text">
                <p className="text-light fw-normal needtxt">
                  Need Help For Professional Design?
                </p>
                <h5 className="fw-normal support-number">+88 01748200588</h5>
              </div>

              <a
                href="contact"
                type="button"
                className="btn support-button2 py-1"
              >
                Contact
              </a>
            </div>
            <div className="col-lg-3 col-0 my-auto buttonblock">
              <a
                href="contact"
                type="button"
                className="btn support-button py-2"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-lg mb-2 text-center">
        <h1 className="text-center py-5">Technology Metrics</h1>
      </div>
      <img src={designfoot} alt="" className="tecmetrixd" />

      <Footer />
    </>
  );
};

export default Design;
