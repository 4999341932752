import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import accuracy from "../img/resources/portfolio/accuracy.png";
import safa from "../img/resources/portfolio/accuracy.png";
import lusterfit from "../img/resources/portfolio/luster-fit.png";
import foodland from "../img/resources/portfolio/fland.png";
import voltec from "../img/resources/portfolio/voltec.png";
import ahis from "../img/resources/portfolio/ahis.png";
import sensormart from "../img/resources/portfolio/sensormart.png";
import mbhuiyan from "../img/resources/portfolio/mbhuiyan.png";
class CustomerLogosCarousel extends React.Component {
  render() {
    const settings = {
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      dots: false,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 520,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    return (
      <div className="customer-logos ">
        <Slider {...settings} className="py-3 slider-pad">
          <div className="slide py-5">
            <img src={accuracy} alt="Image 1" />
          </div>
          <div className="slide py-5">
            <img src={lusterfit} className="mt-4" alt="Image 2" />
          </div>
          <div className="slide py-5">
            <img src={ahis} className="mt-4" alt="Image 3" />
          </div>
          <div className="slide py-5">
            <img src={foodland} alt="Image 4" />
          </div>
          <div className="slide py-5">
            <img src={voltec} className="mt-4" alt="Image 5" />
          </div>
          <div className="slide py-5">
            <img src={safa} alt="Image 6" />
          </div>
          <div className="slide py-5">
            <img src={mbhuiyan} alt="Image 7" />
          </div>
          <div className="slide py-5">
            <img src={sensormart} className="mt-4" alt="Image 8" />
          </div>
        </Slider>
      </div>
    );
  }
}

export default CustomerLogosCarousel;
