import { Link } from "react-router-dom";
import logo from "../img/logo.png";
import Somekoron from "../img/home/Someekoron_Icon.png";
import menu_logo from "../img/menu.png";
import cross_logo from "../img/x.png";
import school from "../img/resources/schoolsys.png";
import flydocx from "../img/resources/flyDoc.png";
import docapp from "../img/resources/docAppint.png";
import feature1 from "../img/resources/feature logo/cloud.png";
import feature2 from "../img/resources/feature logo/design.png";
import feature3 from "../img/resources/feature logo/iot.png";
import "../../node_modules/bootstrap/js/dist/dropdown";

const Nav = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-light p-0 navFontstl border-bottom sticky-top py-1">
        <div className="container-fluid px-lg-5 mx-lg-5">
          <Link className="navbar-brand d-flex align-items-center" to="/">
            <img
              src={logo}
              alt=" Best Software & IT Solution| mPair Technologies"
              className="img-fluid"
            />
            {/* <span className="ms-1 fs-3 logotext">mPair</span> */}
          </Link>
          <button
            className="navbar-toggler custom-toggler"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#nav-modal"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <img
                src={menu_logo}
                alt="mPair Technologies Ltd."
                width="32px"
                height="32px"
              />
            </span>
          </button>

          <div className="collapse navbar-collapse">
            <ul className="navbar-nav me-auto mx-5 mb-lg-0">
              <li className="nav-item me-4">
                <div className="dropdown">
                  <Link
                    className="nav-link dropdown-toggle active navfontm"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Services{" "}
                    <i className="fa-solid fa-chevron-down text-muted font-12"></i>
                  </Link>
                  <div className="dropdown-menu bg-white" id="services">
                    <div className="container">
                      <div className="d-flex justify-content-center pt-4">
                        <a className="txdNone" href="/cloud">
                          <div className="prodHeight d-flex px-4 text-start align-items-center">
                            <img src={feature1} className="mb-3  me-3" alt="" />
                            <p className="fs-6">Cloud & Software</p>
                          </div>
                        </a>
                        <a className="txdNone" href="/design">
                          <div className="prodHeight d-flex px-4 text-start align-items-center">
                            <img src={feature2} className="mb-3 me-3" alt="" />
                            <p className="fs-6">Design & Prototyping</p>
                          </div>
                        </a>
                        <a className="txdNone" href="/embedded">
                          <div className="prodHeight d-flex px-4 text-start align-items-center">
                            <img src={feature3} className="mb-3 me-3" alt="" />
                            <p className="fs-6">Robotics & Automation</p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item me-4">
                <div className="dropdown">
                  <Link
                    className="nav-link dropdown-toggle active navfontm"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Products{" "}
                    <i className="ms-1 fa-solid fa-chevron-down text-muted font-12"></i>
                  </Link>
                  <div className="dropdown-menu bg-white" id="products">
                    <div className="container">
                      <div className="row super_flex pt-4  ">
                        <div className="col-6">
                          {" "}
                          <a className="txdNone" href="/someekoron">
                            <div className="d-flex align-items-center gap-3">
                              <img
                                src={Somekoron}
                                height={50}
                                className="mb-3 Products-bg p-2 rounded "
                                alt=""
                              />
                              <p className="fs-6 fw-semibold">
                                Someekoron <br />{" "}
                                <small className="fs-8 fw-normal">
                                  Project Accounts
                                </small>
                              </p>
                            </div>
                          </a>
                        </div>
                        <div className="col-6">
                          {" "}
                          <a className="txdNone" href="/schoolsys">
                            <div className="d-flex align-items-center gap-3">
                              <img
                                src={school}
                                height={50}
                                className="mb-3 Products-bg p-2 rounded "
                                alt=""
                              />
                              <p className="fs-6 fw-semibold">
                                Schoolsys <br />{" "}
                                <small className="fs-8 fw-normal">
                                  School ERP
                                </small>
                              </p>
                            </div>
                          </a>
                        </div>
                        <div className="col-6 mt-4">
                          {" "}
                          <a className="txdNone" href="/docappoint">
                            <div className="d-flex align-items-center gap-3">
                              <img
                                src={docapp}
                                height={50}
                                className="mb-3 Products-bg p-2 rounded "
                                alt=""
                              />
                              <p className="fs-6 fw-semibold">
                                DocAppoint <br />{" "}
                                <small className="fs-8 fw-normal">
                                  Doctor Appointment
                                </small>
                              </p>
                            </div>
                          </a>
                        </div>

                        <div className="col-6 mt-4">
                          {" "}
                          <a className="txdNone" href="/flydocx">
                            <div className="d-flex align-items-center gap-3">
                              <img
                                src={flydocx}
                                height={50}
                                className="mb-3 Products-bg p-2 rounded "
                                alt=""
                              />
                              <p className="fs-6 fw-semibold">
                                FlyDocX
                                <br />{" "}
                                <small className="fs-8 fw-normal">
                                  Travel Agency Management
                                </small>
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="nav-item me-4">
                <Link className="nav-link active navfontm" to="/clients">
                  Clients
                </Link>
              </li>

              <li className="nav-item me-4">
                <Link className="nav-link active navfontm" to="/aboutUs">
                  About Us
                </Link>
              </li>

              <li className="nav-item me-4">
                <Link className="nav-link active navfontm" to="/career">
                  Careers
                </Link>
              </li>
              <li className="nav-item me-5">
                <Link className="nav-link active navfontm" to="/digital">
                  Digital
                </Link>
              </li>
            </ul>

            <ul className="navbar-nav d-flex">
              <li className="nav-item border-secondary me-4">
                <Link
                  className="nav-link  btn  py-1 px-4 header-button2"
                  type="button"
                  to="/contact"
                >
                  {" "}
                  <i className="far fa-envelope"></i> Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div
        className="modal fade slide  animate__animated animate__slideInLeft"
        id="nav-modal"
        tabindex="-1"
        aria-labelledby="modalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modalSize">
          <div className="modal-content modal-contentsize rounded-0">
            <div className="modal-header border-0">
              <div className="mnavclose">
                <img
                  src={cross_logo}
                  width="20px"
                  height="20px"
                  className="close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
            </div>
            <div className="modal-body border-0 p-0 m-0 mt-4">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row justify-content-center">
                    <div className="col-lg-12 col-12 col-md-12 text-center">
                      <img src={logo} alt="" className="modalIconsize" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 p-0 m-0">
                <div className="container-fluid p-0 m-0">
                  <div className="row text-center mt-3 p-0 m-0">
                    <ul className="navbar-nav m-auto p-0">
                      <li className="nav-item dropdown py-1">
                        <span
                          className="nav-link dropdown-toggle navfontm"
                          id="mnavbarDropdown"
                          role="button"
                          data-bs-target="#mdropmenu"
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                        >
                          Services
                        </span>
                        <div
                          className="accordion-collapse collapse"
                          id="mdropmenu"
                        >
                          <a
                            className="dropdown-item mdropitem pt-2"
                            href="/cloud"
                          >
                            Cloud & Software
                          </a>
                          <a
                            className="dropdown-item mdropitem pt-2"
                            href="/design"
                          >
                            Professional Design
                          </a>
                          <a
                            className="dropdown-item mdropitem pt-2"
                            href="/embedded"
                          >
                            Embedded & Industrial
                          </a>
                        </div>
                      </li>
                      <li className="nav-item dropdown py-1">
                        <span
                          className="nav-link dropdown-toggle navfontm"
                          id="mnavbarDropdown"
                          role="button"
                          data-bs-target="#mdropmenu1"
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                        >
                          Products
                        </span>
                        <div
                          className=" accordion-collapse collapse"
                          id="mdropmenu1"
                        >
                          <a
                            className="dropdown-item mdropitem pt-2"
                            href="/someekoron"
                          >
                            Someekoron
                          </a>
                          <a
                            className="dropdown-item mdropitem pt-2"
                            href="/schoolsys"
                          >
                            SchoolSys
                          </a>
                          <a
                            className="dropdown-item mdropitem pt-2"
                            href="/docappoint"
                          >
                            docAppint
                          </a>
                          <a
                            className="dropdown-item mdropitem pt-2"
                            href="/flydocx"
                          >
                            FlyDocX
                          </a>
                        </div>
                      </li>
                      <li className="nav-item py-1">
                        <a className="nav-link navfontm" href="/clients">
                          Clients
                        </a>
                      </li>
                      <li className="nav-item py-1">
                        <a className="nav-link navfontm" href="/aboutUs">
                          About Us
                        </a>
                      </li>
                      <li className="nav-item py-1">
                        <a className="nav-link navfontm" href="/career">
                          Career
                        </a>
                      </li>
                      <li className="nav-item py-1">
                        <a className="nav-link navfontm" href="/digital">
                          Digital
                        </a>
                      </li>
                      <li className="nav-item py-1">
                        <a className="nav-link navfontm" href="/contact">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
