import React from "react";
import profileCompany from "../img/aboutUs/mPairTechnologiesServiceProfile.pdf";
import { Link } from "react-router-dom";
const footer = () => {
  return (
    <>
      <div className="container-fluid advertisement text-center">
        <h1 className="advertisement-heading pt-5 mb-4 pseudoEl">
          Pair With Us
        </h1>
        <p className="text-center mb-0">
          mPair Technologies is always commited to fullfill your business dream
          and keeps our clients happy.
        </p>
        <div className="container text-center mt-3">
          <Link
            type="button"
            to="contact"
            className="btn header-button py-2 my-4"
          >
            Get A Quote
          </Link>
        </div>
      </div>

      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-12 col-md-3">
              <h6>Services and Solutions</h6>
              <ul className="footer-lists list-unstyled">
                <li>
                  <Link to="/embedded">Robotics technology</Link>
                </li>
                <li>
                  <Link to="/embedded">Iot Automation</Link>
                </li>
                <li>
                  <Link to="/cloud">Cloud Software</Link>
                </li>
                <li>
                  <Link to="/cloud">Mobile Application</Link>
                </li>
                <li>
                  <Link to="/cloud">Desktop Software</Link>
                </li>
                <li>
                  <Link to="/design">Design Solution</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-12 col-md-3">
              <h6>Product</h6>
              <ul className="footer-lists list-unstyled">
                <li>
                  <Link to="/">Accordesk</Link>
                </li>
                <li>
                  <Link to="/schoolsys">SchoolSys</Link>
                </li>
                <li>
                  <Link to="/">docAppoint</Link>
                </li>
                <li>
                  <Link to="/sowda">Sowda App</Link>
                </li>
                <li>
                  <Link to="/flydocx">FlydocX</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-12 col-md-3">
              <h6>Company</h6>
              <ul className="footer-lists list-unstyled">
                <li>
                  <Link to="/aboutUs">About Us</Link>
                </li>
                <li>
                  <Link to="/career">Jobs & Careers</Link>
                </li>
                <li>
                  <Link to="/allBlogView">Blogs</Link>
                </li>
                <li>
                  <Link to="/aboutUs">Stories & Team</Link>
                </li>
                <li>
                  <Link to={profileCompany} target="_blank">
                    Company Profile
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-12 col-md-3">
              <h6>Contact mPair</h6>
              <ul className="footer-lists list-unstyled">
                <li>
                  <Link to="/contact">
                    <i className="fa fa-envelope"></i> Get in Touch
                  </Link>
                </li>
              </ul>
              <p className="footer-contact-text">
                M.B. Complex, 90 High Level Road, <br />
                Chattogram, Bangladesh
              </p>
              <h6>Follow Us</h6>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Link
                    target="_blank"
                    to="https://www.linkedin.com/company/mpair-technologies"
                    className="social-links"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    target="_blank"
                    to="https://www.facebook.com/mPairtechnologies"
                    className="social-links"
                  >
                    <i className="fa-brands fa-facebook-square"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    target="_blank"
                    to="https://www.instagram.com/mpairtech"
                    className="social-links"
                  >
                    <i className="fa-brands fa-instagram-square"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    target="_blank"
                    to="https://www.youtube.com/channel/UCsBETDtK_9Bc1_Oq00zTTUA"
                    className="social-links"
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </Link>
                </li>
              </ul>
              <p className="copyrightText">&copy; mPair 2020-2022</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
