import React from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import iote from "../img/embedded/iote.png";
import auto from "../img/embedded/auto.png";
import pcbe from "../img/embedded/pcbe.png";
import emb from "../img/embedded/emb_back.png";
import iot from "../img/embedded/iot.png";
import pcb from "../img/embedded/pcb.png";
import industry from "../img/embedded/industry.png";
import toufiq from "../img/resources/toufiq1.png";
import python from "../img/embedded/Python.png";
import arduino from "../img/embedded/Arduino.png";
import altium from "../img/embedded/altium.png";
import cad from "../img/embedded/KiCad.png";
import micro from "../img/embedded/Microchip.png";
const embedded = () => {
  return (
    <>
      <Nav />

      <div className="container-fluid client__s">
        <img src={emb} alt="" className="imgWatermark" />
        <section className="container-md text-start mb-5 px-lg-5 px-md-5 px-0">
          <div className="servicesCover">
            <h1 className="mb-4 mt-4">Embedded & Industrial</h1>

            <div className="servicesText">
              <h3 className="mb-2">Creating Smarter Solution</h3>
              <p className="servicesSubText">
                mPair Technologies assures better solutions for Embedded &
                Industrial revolution. The fourth industrial revolution is on
                the way that should make life easier, better and more
                productive. A simple idea can change the future but perfect
                execution of the idea is more important for the revolution.
              </p>
            </div>
          </div>
        </section>

        {/*  */}
        <div className="container px-0 px-lg-5 px-md-5 pb-5 mb-5">
          <div className="row">
            <div className="col-lg-4 pb-lg-0 pb-4">
              <div className="card box_shadow_ ">
                <div className="position-relative">
                  <div className="position-absolute top-50 bottom-50 pt-5 mt-5 ps-4 translate-middle-y start-0 ">
                    <div className="bg-white p-2 bgicon">
                      {" "}
                      <img alt="..." className="serviceIcons" src={iot} />
                    </div>
                  </div>
                </div>
                <div className="">
                  <img
                    src={iote}
                    className="p-0 m-0 card-img cardWidthF rounded-bottom-0"
                    alt="..."
                  />
                </div>

                <div className="card-body p-3 mb-4">
                  <h1 className="fs-5 fw-semibold mt-4">
                    Robotics & IoT Development
                  </h1>
                  <p className="card-text textjustify">
                    With the introduction of intellectual and automation
                    learning, Robotics and Internet of Things (IoT) amplifies
                    the learning curve to a far greater extent. mPair
                    Technologies provides IoT and Robotics solutions with
                    intelligence of technology.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pb-lg-0 pb-4">
              <div className="card box_shadow_ ">
                <div className="position-relative">
                  <div className="position-absolute top-50 bottom-50 pt-5 mt-5 ps-4 translate-middle-y start-0 ">
                    <div className="bg-white p-2 bgicon">
                      {" "}
                      <img alt="..." className="serviceIcons" src={industry} />
                    </div>
                  </div>
                </div>
                <div className="">
                  <img
                    src={auto}
                    className=" p-0 m-0 card-img cardWidthF rounded-bottom-0"
                    alt="..."
                  />
                </div>

                <div className="card-body p-3 mb-4">
                  <h1 className="fs-5 fw-semibold mt-4">
                    Industrial Automation Solution
                  </h1>
                  <p className="card-text textjustify">
                    Most of the industries are now approaching towards smart
                    industry technology because of rapidly increasing demand for
                    fast production capacities. mPair Technologies has highly
                    skilled and experienced teams to provide you the best
                    automation solution.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 ">
              <div className="card box_shadow_ ">
                <div className="position-relative">
                  <div className="position-absolute top-50 bottom-50 pt-5 mt-5 ps-4 translate-middle-y start-0 ">
                    <div className="bg-white p-2 bgicon">
                      {" "}
                      <img alt="..." className="serviceIcons" src={pcb} />
                    </div>
                  </div>
                </div>
                <div className="">
                  <img
                    src={pcbe}
                    className="p-0 m-0 card-img cardWidthF rounded-bottom-0"
                    alt="..."
                  />
                </div>

                <div className="card-body p-3 mb-4">
                  <h1 className="fs-5 fw-semibold mt-4">Product PCB Design </h1>
                  <p className="card-text textjustify">
                    PCB design service brings electronic circuits to life in the
                    physical form. mPair Technologies Develops any kind of
                    electronic products with the latest PCB design and
                    fabrication process both for individual and industrial
                    purposes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
      </div>

      <div className="container-lg mb-5 mt-3 pt-5">
        <div className="row helpcard justify-content-center mx-auto">
          <div className="supportCard row p-0">
            <div className="col-lg-2 col-5">
              <img
                src={toufiq}
                alt="Sales | Aminul Asfi | mPair Technologies"
                className="cloud_image"
              />
            </div>
            <div className="col-lg-7 col-7 ">
              <div className="support-contact-text">
                <p className="text-light fw-normal needtxt">
                  Need Help For Automation Solutions?
                </p>
                <h5 className="fw-normal support-number">+88 01748200588</h5>
              </div>

              <a
                href="contact"
                type="button"
                className="btn support-button2 py-1"
              >
                Contact
              </a>
            </div>
            <div className="col-lg-3 col-0 my-auto buttonblock">
              <a
                href="contact"
                type="button"
                className="btn support-button py-2"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-5 text-center">
        <h1 className="text-center py-5">Technology Metrics</h1>
        <div className="row align-items-center">
          <div className="col" width="20%">
            <img src={python} alt="" className="techMetricsLogo" />
          </div>

          <div className="col" width="20%">
            <img src={arduino} alt="" className="techMetricsLogo" />
          </div>
          <div className="col" width="20%">
            <img src={micro} alt="" className="techMetricsLogo" />
          </div>
          <div className="col" width="20%">
            <img src={cad} alt="" className="techMetricsLogo" />
          </div>
          <div className="col" width="20%">
            <img src={altium} alt="" className="techMetricsLogo" />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default embedded;
