import React, { useEffect } from "react";
import Nav from "../components/navDigital";
import Footer from "../components/footer";
import nikeIcon from "../img/digital/nike.png";
import brewBites from "../img/digital/digitalheader.png";
import marketing from "../img/digital/m_design.png";
import content from "../img/digital/c_content.png";
import product from "../img/digital/prototype.png";
import strategy from "../img/digital/strategy.png";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import temp1 from "../img/digital/temp1.png";
import temp2 from "../img/digital/temp2.png";
import temp3 from "../img/digital/temp3.png";
import temp4 from "../img/digital/temp4.png";
import temp5 from "../img/digital/temp5.png";
import accuracy from "../img/resources/portfolio/accuracy.png";
import safa from "../img/resources/portfolio/accuracy.png";
import lusterfit from "../img/resources/portfolio/luster-fit.png";
import foodland from "../img/resources/portfolio/fland.png";
import voltec from "../img/resources/portfolio/voltec.png";
import ahis from "../img/resources/portfolio/ahis.png";
import sensormart from "../img/resources/portfolio/sensormart.png";
import mbhuiyan from "../img/resources/portfolio/mbhuiyan.png";
import toufiq from "../img/toufiq1.png";

import CustomerLogosCarousel from "../components/CustomerLogosCarousel";
const Digital = () => {
  const logos = [
    "../img/resources/portfolio/accuracy.png",
    "../img/resources/portfolio/accuracy.png",
    "../img/resources/portfolio/luster-fit.png",
    "../img/resources/portfolio/fland.png",

    // Add more logo image URLs as needed
  ];
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const container = document.querySelector(".scroll-item-box");
    const sections = gsap.utils.toArray(".scroll-item-box section");
    const mask = document.querySelector(".mask");
    let scrollTween = gsap.to(sections, {
      xPercent: -57 * (sections.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".scroll-item-box",
        pin: true,
        scrub: 1,
        markers: false,
      },
    });

    gsap.to(mask, {
      width: "100%",
      scrollTrigger: {
        trigger: ".wrapper",
        markers: false,
        scrub: 1,
      },
    });
  }, []);

  return (
    <>
      <Nav />

      <div className="digital">
        <div className="container">
          <h1 className="text-center coverHeading-digital pt-5">
            Crafting, Creativity, and Connectivity
            <div className="">
              <img src={brewBites} className="w-75 mt-5 mb-4" alt="" />
            </div>
          </h1>
        </div>
        {/*  */}
        <div className="container">
          <div className="text-center why-logo rounded-5 px-4 bg-white">
            <h1 className="text-center pt-5 mb-4" style={{ color: "#363636" }}>
              What Makes Branding Vital for Your Business?
            </h1>
            <p className="px-lg-5 px-0 px-md-5 pb-5 mx-lg-5 mx-md-5 mx-0 fs-5 m-0 text-center text_justify">
              <img
                src={nikeIcon}
                height={35}
                className="p-0 m-0"
                style={{ transform: "rotate(-25deg)" }}
                alt=""
              />
              The Nike logo is a prime example of why a brand logo is important
              for a business. It serves as a visual anchor for the brand,
              facilitating instant recognition, building trust, creating
              emotional connections, and allowing for versatile branding
              efforts.{" "}
              <Link to="/blog/58" className="fs-5 txdNone text-primary">
                Learn More
              </Link>
            </p>
          </div>
        </div>

        {/*  */}

        {/*  */}

        <section className="shikhuBg">
          <div className="container text-center pt-5 mt-5">
            <h1 className=" digitalFontH pt-5">
              Maximize Your{" "}
              <span style={{ color: "#F5962E" }}>Business Growth</span>
            </h1>

            <h1 style={{ color: "#363636" }}>
              with our creative design process
            </h1>
          </div>
        </section>
      </div>

      <div>
        <div className="container py-5 my-4">
          <div className="text-center mb-5 pb-3">
            <h1>How We Elevate Your Business</h1>
            <p>
              Our expertise transforms your brand, engages your audience, and
              fuels growth. Elevate your success with us.
            </p>
          </div>
          <div className="row px-5 d-flex justify-content-cnter">
            <div className="col-lg-3 text-center px-5">
              {" "}
              <img src={content} alt="" className="digital-service-image" />
              <p className="fs-5 mt-3">Creative Content</p>
            </div>
            <div className="col-lg-3 text-center">
              {" "}
              <img src={product} alt="" className="digital-service-image" />
              <p className="fs-5 mt-3">Product Prototyping</p>
            </div>
            <div className="col-lg-3 text-center">
              {" "}
              <img src={marketing} alt="" className="digital-service-image" />
              <p className="fs-5 mt-3">Marketing Design</p>
            </div>
            <div className="col-lg-3 text-center">
              {" "}
              <img src={strategy} alt="" className="digital-service-image" />
              <p className="fs-5 mt-3">Innovative Strategy</p>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div class="wrapper bg-custom-digital">
          <div class="container-fluid scroll-item-box p-0 scrollx d-flex">
            <section class="p-5 mt-5">
              <span className="span-dig">LusterFit Ltd.</span>
              <h1 className="head-dig">Crafting Identity</h1>

              <div>
                <div>
                  <img className="digital-temp-img" alt="mPair" src={temp1} />
                </div>
              </div>
            </section>
            <section class="p-5 mt-5">
              <span class="span-dig">Food Land</span>
              <h1 className="head-dig">Creative Visualization</h1>

              <div>
                <div>
                  <img className="digital-temp-img" alt="mPair" src={temp2} />
                </div>
              </div>
            </section>
            <section class="p-5 mt-5">
              <span class="span-dig">Al-Hidaayah Intl School</span>
              <h1 className="head-dig">Visual Experience</h1>

              <div>
                <div>
                  <img className="digital-temp-img" alt="mPair" src={temp3} />
                </div>
              </div>
            </section>

            <section class="p-5 mt-5">
              <span class="span-dig">Accuracy Engineering</span>
              <h1 className="head-dig">Unique Branding</h1>

              <div>
                <div>
                  <img className="digital-temp-img" alt="mPair" src={temp4} />
                </div>
              </div>
            </section>
            <section class="p-5 mt-5">
              <span class="span-dig">Sowda App</span>
              <h1 className="head-dig">Creative Expression</h1>

              <div>
                <div>
                  <img className="digital-temp-img" alt="mPair" src={temp5} />
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="marketing-digital pt-lg-4">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center px-lg-5 p-0 reverse ">
            <div className="col-lg-5 ps-lg-5 p-lg-2 pb-lg-0">
              <div className="market-digital text-center">
                <img
                  className="ms-lg-5 sales-img text-center m-0"
                  src={toufiq}
                  alt=""
                />
              </div>
            </div>

            <div className="col-lg-7 ps-2 pt-lg-0 pt-4">
              <div className="text-lg-start text-md-start text-center">
                <h1 className="mt-2 dig-market-head">
                  Why go with mPair Digital?
                </h1>
                <br />
                <span className="pe-4 pname1">
                  Our highly skilled and experienced teams specialize in
                  individual services, ensuring the timely delivery of
                  high-value solutions within budget.
                </span>{" "}
                <br />
                <br />
                <span className="pname2">
                  {" "}
                  Call our sales team at +880 1748200588{" "}
                </span>
                <small></small>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="py-5">
        <div class="slider pt-3">
          <div class="slide-track">
            <div class="slide">
              <img className="" src={accuracy} height="50" width="120" alt="" />
            </div>
            <div class="slide">
              <img
                className="mt-2"
                src={lusterfit}
                height="50"
                width="85"
                alt=""
              />
            </div>
            <div class="slide">
              <img className="mt-3" src={ahis} height="40" width="140" alt="" />
            </div>
            <div class="slide">
              <img className="" src={foodland} height="50" width="140" alt="" />
            </div>
            <div class="slide">
              <img
                className="mt-3"
                src={voltec}
                height="35"
                width="140"
                alt=""
              />
            </div>
            <div class="slide">
              <img className="" src={safa} height="50" width="140" alt="" />
            </div>
            <div class="slide">
              <img className="" src={mbhuiyan} height="50" width="140" alt="" />
            </div>
            <div class="slide">
              <img
                className="mt-2"
                src={sensormart}
                height="40"
                width="180"
                alt=""
              />
            </div>
          </div>
        </div>
      </section> */}
      <section>
        {" "}
        <CustomerLogosCarousel />
      </section>
      <Footer />
    </>
  );
};

export default Digital;
