import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Nav from "../components/nav";
import Footer from "../components/footer";
import Someekoron from "../img/home/Someekoron_Logo.png";
import software from "../img/home/software.png";
import robotics from "../img/home/robotics.png";
import prototype from "../img/home/prototyping.png";
import ui_accor from "../img/home/accor.png";
import ui_sys from "../img/home/schoolsys.png";
import ui_fly from "../img/home/flydocx-01.png";
import ui_doc from "../img/home/Docappoint-01.png";
import ecom1 from "../img/home/cloud.png";
import ecom2 from "../img/home/multi.png";
import ecom3 from "../img/home/single.png";
import ecom4 from "../img/home/sub.png";
import enterpriseLog from "../img/home/accordeskside.png";
import ahis from "../img/resources/portfolio/ahis.png";
import national from "../img/resources/portfolio/national.png";
import parkv from "../img/resources/portfolio/parkv.png";
import amanah from "../img/resources/portfolio/amanah.png";
import epic from "../img/resources/portfolio/epic.png";
import voltec from "../img/resources/portfolio/voltec.png";
import Marketing from "../components/marketing";

const Home = () => {
  const [blogsData, setBlogsData] = useState([]);
  function recieve() {
    fetch("https://server.mpairtech.com/mpserver/blogs_recieve", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setBlogsData(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    recieve();
  }, []);

  return (
    <>
      <Nav />

      <section className="mainhead">
        <div className="container">
          <div className="row main_sec align-items-center justify-content-center pb-lg-5 pb-0">
            <div className="col-lg-9">
              <h1 className="pt-lg-0 pt-md-0 pt-5 coverHeading text-lg-center text-center">
                Ideate The Future
              </h1>
              <p className="coverText text-center">
                Exploring creative ideas to ensure the green technologies for a
                better future world. <br />
                Utilizing the latest technology led by a promosing team will
                provide you the best and innovative solutions.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="total_sec mt-5 mt-lg-0 mt-md-0">
        <div className="enterprise">
          <div className="container px-5 py-3 py-lg-0 py-md-0 my-auto accordesk_card">
            <div className="row align-items-center">
              <div className="col-lg-3 someekoron">
                <img src={Someekoron} alt="" />
              </div>
              <div className="col-lg-5">
                <p className="mb-lg-0 mb-md-0 mb-4 coverTextAccordesk">
                  {" "}
                  Experience rapid business growth through our integrated
                  approach of Project Accounts Solution.
                </p>
                <Link
                  type="button"
                  to="/"
                  className="py-2 ep-button px-3 mt-3 rounded"
                >
                  Learn More <i className="fa fa-arrow"></i>
                </Link>
              </div>
              <div className="col-lg-4 text-center">
                {" "}
                <img className="enterprise_logo" src={enterpriseLog} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <br />
        </div>
        <div className="features container-fluid py-5 mt-4">
          <h1 className="text-center mt-5 pt-5">
            Pair Your Business With mPair's Expertise
          </h1>
          <p
            className="text-center mt-2 mb-lg-5"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            We provide our next level services, that your business can meet
            expected goals.
          </p>
          <div className="container border rounded">
            <div className="row">
              <div className="col-lg-4 col-12 feature py-3  border-end">
                <div className="py-3">
                  <img src={software} alt="mPair" className="service-icon" />
                  <p className="featureHeader mb-1">Cloud & Software</p>
                  <p className="featureText mt-2">
                    Delivering on demand secured & stable Software solution
                  </p>
                  <Link className="featureTexte mt-2" to="/cloud">
                    Explore more
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-12 feature py-3 border-end">
                <div className="  py-3">
                  <img src={prototype} alt="mPair" className="service-icon" />
                  <p className="featureHeader mb-1">Design & Prototyping</p>
                  <p className="featureText mt-2">
                    Advancing the human experience through creative and premium
                    designs.
                  </p>
                  <Link className="featureTexte mt-2" to="/design">
                    Explore more
                  </Link>
                </div>
              </div>

              <div className="col-lg-4 col-12 feature py-3">
                <div className="  py-3">
                  <img src={robotics} alt="mPair" className="service-icon" />
                  <p className="featureHeader mb-1">Robotics & Automation</p>
                  <p className="featureText mt-2">
                    Approaching towards a smart life with intelligence of
                    technology.
                  </p>
                  <Link className="featureTexte mt-2" to="/embedded">
                    Explore more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pillas product-main pb-lg-4">
        <div className="container  ">
          <h1 className="text-center  mt-lg-5 mt-0 pt-5">
            Automate Your Business Process
          </h1>
          <p className="mb-3 text-center">
            Explore mPair Products to meet the diverse requirements of desired
            industries, offering quality solutions for every sector.
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <div className="p-lg-3 p-md-3 p-0 w-100">
              <nav className="nav-tab-bg">
                <div
                  className="nav nav-tabs border-0 justify-content-center mb-lg-4 mb-md-4 mb-0"
                  id="nav-tab"
                  role="tablist"
                >
                  <button
                    className="nav-link active"
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    {/* <img src={es} alt="" /> */}
                    Someekoron
                  </button>
                  <button
                    className="nav-link"
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    {" "}
                    {/* <img src={school} alt="" /> */}
                    SchoolSys
                  </button>
                  <button
                    className="nav-link"
                    id="nav-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-contact"
                    type="button"
                    role="tab"
                    aria-controls="nav-contact"
                    aria-selected="false"
                  >
                    {" "}
                    {/* <img src={docapp} alt="" /> */}
                    DocAppoint
                  </button>
                  <button
                    className="nav-link"
                    id="nav-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-contact2"
                    type="button"
                    role="tab"
                    aria-controls="nav-contact2"
                    aria-selected="false"
                  >
                    {" "}
                    {/* <img src={flydocx} alt="" /> */}
                    FlyDocx
                  </button>
                </div>
              </nav>
              <div className="tab-content p-3" id="nav-tabContent">
                <div
                  className="tab-pane fade active show"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="container-fluid row p-0 align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12 py-3">
                      {" "}
                      <div className="text-left">
                        <p className="pname1 mb-1"> Someekoron</p>
                        <p className="pname2 mb-2">
                          Someekoron is an Enterprise Solution platform designed
                          to integrate core business processes across an
                          organization improved collaboration across
                          departments.
                        </p>
                        <ul className="list-unstyled mb-1">
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Project Management
                          </li>

                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Event Management
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Task Management
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Store & Procurement
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            HR Management
                          </li>
                        </ul>
                        <Link
                          className="font-14 text-secondary mb-0"
                          to="/someekoron"
                        >
                          Learn More
                        </Link>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 px-3 text-lg-end text-center  user_interface_sub">
                      <img
                        className="user_interface rounded p-1 "
                        src={ui_accor}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <div className="container-fluid row p-0 align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12 py-3">
                      {" "}
                      <div className="text-left">
                        <p className="pname1 mb-1">SchoolSys</p>
                        <p className="pname2 mb-2">
                          SchoolSys is a comprehensive software solution that
                          integrates various administrative, academic, and
                          communication processes within an educational
                          institution.
                        </p>
                        <ul className="list-unstyled mb-1">
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Student Information Management
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Grade and Exam Management
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Fees Management
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Library Management
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Staff and HR Management
                          </li>
                        </ul>
                        <Link
                          className="font-14 text-secondary mb-0"
                          to="/schoolsys"
                        >
                          Learn More
                        </Link>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 px-3 text-lg-end text-center user_interface_sub">
                      <img
                        className="user_interface rounded p-1 "
                        src={ui_sys}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="nav-contact"
                  role="tabpanel"
                  aria-labelledby="nav-contact-tab"
                >
                  <div className="container-fluid row p-0 align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12 pt-3">
                      {" "}
                      <div className="text-left">
                        <p className="pname1 mb-1">DocAppoint</p>
                        <p className="pname2 mb-2">
                          docAppoint is a platform that enables user to book
                          appointments online, notify them and helps doctors to
                          manage their schedules & reduce wait times.
                        </p>
                        <ul className="list-unstyled mb-1">
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Doctor’s Appointment Management
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Patient Queue Management
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Multiple User Admin Role
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Daily & Monthly Reports
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Real Time Notification
                          </li>
                        </ul>
                        <Link
                          className="font-14 text-secondary mb-0"
                          to="/docappoint"
                        >
                          Learn More
                        </Link>
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-12 col-sm-12 px-3 text-lg-end text-center">
                      <img
                        className="user_interface rounded p-1 "
                        src={ui_doc}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-contact2"
                  role="tabpanel"
                  aria-labelledby="nav-contact-tab"
                >
                  <div className="container-fluid row p-0 align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12 py-3">
                      {" "}
                      <div className="text-left">
                        <p className="pname1 mb-0">FlyDocx</p>
                        <p className="pname2 mb-0">
                          Flydocx is a Tour & Travel Agency Management System
                          that can manage all activities of a Travel Agency
                          including Customer, Vendor, and Accounts Management.
                        </p>
                        <ul className="list-unstyled">
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Customer Data Management
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Vendor & Agent Automation
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Accounts Management
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Staff Management
                          </li>
                          <li>
                            <i className="fa-solid fa-check-double mx-1"></i>
                            Daily & Monthly Reports
                          </li>
                        </ul>
                        <Link
                          className="font-14 text-secondary mb-0"
                          to="/flydocx"
                        >
                          Learn More
                        </Link>
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-12 col-sm-12 px-3 text-lg-end text-center user_interface_sub">
                      <img
                        className="user_interface rounded p-1 "
                        src={ui_fly}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Marketing />

      <section>
        <div className="ecommerce-section py-3">
          <div
            className="container px-lg-2  py-2 px-md-4 mb-4"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <h1 className="text-center mt-5">E-commerce Solutions</h1>
            <p className="m-0 text-center">
              Want to get started with your e-commerce business? We are at your
              service.
            </p>
            <div className="row mt-5  justify-content-center px-lg-0 px-4">
              <div className="col-lg-5 mb-lg-0 mb-5">
                <div className="row px-2">
                  <div className="col-lg-3 col-3 text-center pt-1">
                    <img
                      src={ecom3}
                      alt="Automation | Best IT Solution \ mPair Technologies"
                      className="contentImage2"
                    />
                  </div>
                  <div className="col-lg-9 col-9 text-left">
                    <p className="pname3 my-0">Single Vendor</p>
                    <p className="pname4 mb-0">
                      Amplify your brand and sales value with our single vendor
                      solution.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <div className="row px-2">
                  <div className="col-lg-3 col-3 text-center pt-1">
                    <img
                      src={ecom2}
                      alt="Automation | Best IT Solution \ mPair Technologies"
                      className="contentImage2"
                    />
                  </div>
                  <div className="col-lg-9 col-9 text-left">
                    <p className="pname3 my-0">Multi Vendor</p>
                    <p className="pname4 mb-0">
                      Deploy and manage your very own marketplace on the web.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5 justify-content-center px-lg-0 px-4">
              <div className="col-lg-5 mb-lg-0 mb-5">
                <div className="row px-2">
                  <div className="col-lg-3 col-3 text-center pt-1">
                    <img
                      src={ecom1}
                      alt="Automation | Best IT Solution \ mPair Technologies"
                      className="contentImage2"
                    />
                  </div>
                  <div className="col-lg-9 col-9 text-left">
                    <p className="pname3 my-0">Cloud POS</p>
                    <p className="pname4 mb-0">
                      Assemble all your business and sales operations in one
                      place.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <div className="row px-2">
                  <div className="col-lg-3 col-3 text-center pt-1">
                    <img
                      src={ecom4}
                      alt="Automation | Best IT Solution \ mPair Technologies"
                      className="contentImage2"
                    />
                  </div>
                  <div className="col-lg-9 col-9 text-left">
                    <p className="pname3 my-0">Subscription Model</p>
                    <p className="pname4 mb-0">
                      Ensure consistent and recurring revenue with subscription
                      based business.
                    </p>
                  </div>
                </div>
              </div>
              <div className="container text-center mt-5">
                <Link
                  type="button"
                  to="/contact"
                  className="btn text-white header-button py-2 my-4"
                >
                  Get A Quote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="clientf">
        <div className="container pb-5">
          <div className="row align-items-center">
            <div className="col-lg-12 mt-4">
              {/* <div className="pt-5 ">
                <div className="d-flex align-items-center jcs">
                  <div className="">
                    {" "}
                    <h1 className="featureSecond-heading text-lg-end text-center  fs-1">
                      {" "}
                      20+
                    </h1>
                    <h1 className="featureF-heading fs-4">Clients</h1>{" "}
                  </div>
                  <div className="bord pe-3"></div>

                  <div className="ps-3">
                    {" "}
                    <h1 className="featureSecond-heading text-lg-end text-center  fs-1">
                      {" "}
                      100K+
                    </h1>
                    <h1 className="featureF-heading fs-4"> User</h1>{" "}
                  </div>
                </div>
              </div> */}

              <h1 className="fw-semibold featureSecond-heading color-navy text-lg-start text-center pt-5 mb-5">
                <span className="text-secondary featureSecond-heading fw-semibold">
                  {" "}
                  Since 2021{" "}
                </span>
                <br /> More than{" "}
                <u className="featureSecond-heading color-navy fw-semibold">
                  20 brands
                </u>{" "}
                have paired with us.
              </h1>
              {/* <p className="text-lg-start text-center porttext p-0">
                We are happy to deliver client's satisfaction <br /> and success
                with assuring quality service.
              </p> */}
            </div>
            <div className="col-lg-12 col-sm-12">
              <div className="row container-fluid mx-0 px-0 mt-0 align-items-center">
                <div
                  className="col-lg-2 col-md-4 col-6 text-center py-lg-5 py-3 mx-auto"
                  data-aos="fade-in"
                  data-aos-delay="200"
                >
                  <img src={national} alt="" className="portimgL grayscale" />
                </div>

                <div
                  className="col-lg-2 col-md-4  col-6 text-center py-lg-5 py-3 mx-auto"
                  data-aos="fade-in"
                  data-aos-delay="200"
                >
                  <img src={ahis} alt="" className="portimgXs grayscale" />
                </div>

                <div
                  className="col-lg-2 col-md-4 col-6  text-center py-lg-5 py-3 mx-auto"
                  data-aos="fade-in"
                  data-aos-delay="200"
                >
                  <img src={epic} alt="" className="portimg grayscale" />
                </div>

                <div
                  className="col-lg-2 col-md-4 col-6  text-center py-lg-5 py-3 mx-auto"
                  data-aos="fade-in"
                  data-aos-delay="200"
                >
                  <img src={parkv} alt="" className="portimgM grayscale" />
                </div>
                <div
                  className="col-lg-2 col-md-4 col-6  text-center py-lg-5 py-3 mx-auto"
                  data-aos="fade-in"
                  data-aos-delay="200"
                >
                  <img src={amanah} alt="" className="portimgL grayscale" />
                </div>

                <div
                  className="col-lg-2 col-md-4 col-6  text-center py-lg-5 py-3 mx-auto"
                  data-aos="fade-in"
                  data-aos-delay="200"
                >
                  <img src={voltec} alt="" className="portimgS grayscale" />
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <Link to="/clients" className="link-secondary link-2">
                  View All <i class="fa-solid fa-angle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-blog">
        <div className="container py-5">
          <h1 className="text-center text-dark">Article For Your Business</h1>
          <div className="row my-4 d-flex justify-content-center">
            {blogsData.slice(0, 3).map((blog) => (
              <div key={blog.id} className="col-lg-4 mb-lg-0 mb-5 ">
                <div className="box_shadow__2 rounded-2">
                  <div className="d-flex ">
                    <img
                      className="blog-head w-100"
                      src={
                        "https://server.mpairtech.com/public/image/" +
                        blog.blogImage
                      }
                      alt={blog.blogTitle}
                    />
                  </div>
                  <div className="p-3 pt-1">
                    <h1 className="mb-1 font-18 mt-3 text-dark">
                      {blog.blogTitle}
                    </h1>
                    <p className="mb-0 font-12 text-muted">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${blog.description.slice(0, 100)} ${
                            blog.description.length > 100 ? "..." : ""
                          }`,
                        }}
                      />
                    </p>

                    <Link
                      className="m-0 p-0 featureTexte font-11"
                      to={`/blog/${blog.id}`}
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="text-center mt-4">
            {" "}
            <Link
              type="button"
              to="/allBlogView"
              className="py-2 ep-button px-3 mt-3 rounded"
            >
              Learn More
            </Link>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
