import React from "react";
import toufiq from "../img/toufiq1.png";
import { Link } from "react-router-dom";
const Marketing = () => {
  return (
    <>
      <section className="marketing pt-lg-4">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center px-lg-5 p-0 reverse ">
            <div className="col-lg-6 ps-lg-5 p-lg-2 pb-lg-0">
              <div className="market  text-center">
                <img className="ms-lg-5 text-center m-0" src={toufiq} alt="" />
              </div>
            </div>

            <div className="col-lg-6 ps-2 pt-lg-0 pt-4">
              <div className="text-lg-start text-md-start text-center">
                <h1 className="mt-2">Why go with mPair?</h1>
                <span className="pe-4">
                  Our highly skilled and experienced teams specialize in
                  individual services, ensuring the timely delivery of
                  high-value solutions within budget.
                </span>{" "}
                <br />
                <span> Call our sales team at +880 1748200588 </span> <br />
                <small>
                  {" "}
                  <br />
                  <Link
                    to="/allBlogView"
                    className="allBlogView text-dark fw-bold"
                  >
                    Read Article for Your Business
                  </Link>
                </small>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Marketing;
