import React from "react";
import err from "../img/404.png";
const NotFoundPage = () => {
  return (
    <>
      <div className="text-center mt-5 pt-5 fs-1 fw-bold"></div>
      <div className="d-flex justify-content-center mt-5 ">
        <img src={err} className="rounded-5" alt="" />
      </div>
    </>
  );
};

export default NotFoundPage;
