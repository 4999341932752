import React from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import banner from "../img/home/schoolsys.png";
import sm from "../img/schoolsys/sm.png";
import em from "../img/schoolsys/em.png";
import hm from "../img/schoolsys/hm.png";
import fm from "../img/schoolsys/fm.png";

const schoolsys = () => {
  return (
    <>
      <Nav />
      <section className="schoolBanner pt-lg-5 pt-1">
        <div className="container pt-sm-0">
          <div className="row mt-5">
            <div className="col-lg-6">
              <h1 className=" text-dark">
                Digitalization of
                <br /> School Management <br />
                With <span className="product-name-text">SchoolSys</span>
              </h1>

              <h6 className="text-dark">
                Welcome to a comprehensive software solution that integrates
                various administrative, academic, and communication processes
                within an educational institution.
              </h6>
              <br />
            </div>
            <div className="col-lg-6 text-center">
              <div className="sysBan">
                <img src={banner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sysItem">
        <div className="container p-5 docitem">
          <h1 className="text-center mb-5">
            {" "}
            Paperless School ERP Solution: A way forward to the future
          </h1>
          <div className="row text-center">
            <div className="col-lg-3 box_shadow  mb-lg-0 mb-md-3 mb-5">
              <div className="p-5 box_2_shadow_1">
                <img src={sm} alt="" /> <h5>Student Management</h5>
                <p className="text-center">
                  Manage financial and academic activities of every student
                </p>
              </div>
            </div>
            <div className="col-lg-3   box_shadow  mb-lg-0 mb-md-3 mb-5">
              <div className="box_2_shadow_1 p-5">
                <img src={em} alt="" /> <h5>Exam Management</h5>
                <p className="text-center">
                  Update exam schedule, results & grades of individual students
                </p>
              </div>
            </div>
            <div className="col-lg-3  box_shadow  mb-lg-0 mb-md-3 mb-5 ">
              <div className="p-5 box_2_shadow_1">
                <img src={fm} alt="" /> <h5>Fees Management</h5>
                <p className="text-center">
                  Ensure secure fees management with all due and delays
                </p>
              </div>
            </div>
            <div className="col-lg-3  box_shadow  mb-lg-0 mb-md-3 mb-5">
              <div className="p-5 box_2_shadow_1">
                <img src={hm} alt="" /> <h5>Staff & HR Management</h5>
                <p className="text-center">
                  Manage all HR and Payroll activities of teachers and staff
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default schoolsys;
