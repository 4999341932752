import React from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import banner from "../img/someekoron/pb_dashboard.png";
import somekoron_icon_1 from "../img/someekoron/somee-01.png";
import somekoron_icon_2 from "../img/someekoron/somee-02.png";
import somekoron_icon_3 from "../img/someekoron/somee-03.png";
import somekoron_icon_4 from "../img/someekoron/somee-04.png";

const Someekoron = () => {
  return (
    <>
      <Nav />
      <section className="schoolBanner pt-lg-5 pt-1">
        <div className="container pt-sm-0">
          <div className="row mt-5">
            <div className="col-lg-6">
              <h1 className=" text-dark">
                Effortless <br /> Project Management <br /> With <span></span>
                <span className="product-name-text">Someekoron</span>
              </h1>
              <h6 className="text-dark">
                From Planning to Profit: Experience Business Growth Through Our
                Integrated Approach.
              </h6>
              <br />
            </div>
            <div className="col-lg-6 text-center">
              <div className="sysBan">
                <img src={banner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sysItem">
        <div className="container p-5 docitem">
          <h1 className="text-center mb-5 text-dark">
            {" "}
            Collaborate, Track & Succeed <br />{" "}
            <div className="fs-5">with Enriched Project Management Tool</div>
          </h1>
          <div className="row text-center">
            <div className="col-lg-3 box_shadow  mb-lg-0 mb-md-3 mb-5">
              <div className="p-5 box_2_shadow_1">
                <img src={somekoron_icon_3} alt="" />{" "}
                <h5 className="mt-2">Project Planning</h5>
                <p className="text-center">
                  From Making Quotation to Project Completion; everything done
                  with Someekoron
                </p>
              </div>
            </div>
            <div className="col-lg-3   box_shadow  mb-lg-0 mb-md-3 mb-5">
              <div className="box_2_shadow_1 p-5">
                <img src={somekoron_icon_1} alt="" />{" "}
                <h5 className="mt-2">Project Accounts</h5>
                <p className="text-center">
                  Generate PO & Invoice, track Income & Expense and all Bank
                  Transaction with Someekoron accounts
                </p>
              </div>
            </div>
            <div className="col-lg-3  box_shadow  mb-lg-0 mb-md-3 mb-5 ">
              <div className="p-5 box_2_shadow_1">
                <img src={somekoron_icon_2} alt="" />{" "}
                <h5 className="mt-2">Project Resources</h5>
                <p className="text-center">
                  Allocate in-house and project resource separately with
                  Resource Management
                </p>
              </div>
            </div>
            <div className="col-lg-3  box_shadow  mb-lg-0 mb-md-3 mb-5">
              <div className="p-5 box_2_shadow_1">
                <img src={somekoron_icon_4} alt="" />{" "}
                <h5 className="mt-2">Project Tracking</h5>
                <p className="text-center">
                  Ensure Project Completion within due time with Project Task
                  Management
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Someekoron;
