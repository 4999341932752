import React from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import toufiq from "../img/resources/toufiq1.png";
import cloudbg from "../img/cloud/cloudback.png";
import electron from "../img/cloud/electron.png";
import flutter from "../img/cloud/flutter.png";
import laravel from "../img/cloud/laravel.png";
import Nodejs from "../img/cloud/Nodejs.png";
import reactjs from "../img/cloud/reactjs.png";
import web from "../img/cloud/web.png";
import mobile from "../img/cloud/mobile.png";
import desktop from "../img/cloud/desktop.png";
import web1 from "../img/cloud/webdev.png";
import mobile1 from "../img/cloud/mob.png";
import desktop1 from "../img/cloud/desk.png";

const cloud = () => {
  return (
    <>
      <Nav />

      <div className="container-fluid client__s">
        <img src={cloudbg} alt="" className="imgWatermark" />
        <section className="container-md text-start mb-5 px-lg-5 px-md-5 px-0">
          <div className="servicesCover">
            <h1 className="mb-4 mt-4">Cloud & Software</h1>

            <div className="servicesText">
              <h3 className="mb-2">Making Digital Revolution</h3>
              <p className="servicesSubText">
                Utilizing the latest web technologies, Team mPair will provide
                you the best and innovative Cloud & Software solution.
                Businesses can be more flexible by using their resources from
                any location because of cloud application development services.
              </p>
            </div>
          </div>
        </section>

        <div className="container px-lg-5 px-md-5 px-0 pb-5 mb-5">
          <div className="row">
            <div className="col-lg-4 pb-lg-0 pb-4">
              <div className="card box_shadow_ ">
                <div className="position-relative">
                  <div className="position-absolute top-50 bottom-50 pt-5 mt-5 ps-4 translate-middle-y start-0 ">
                    <div className="bg-white p-2 bgicon">
                      {" "}
                      <img alt="..." className="serviceIcons" src={mobile} />
                    </div>
                  </div>
                </div>
                <div className="">
                  <img
                    src={mobile1}
                    className="p-0 m-0 card-img cardWidthF rounded-bottom-0"
                    alt="..."
                  />
                </div>

                <div className="card-body p-3 mb-4">
                  <h1 className="fs-5 fw-semibold mt-4">
                    Mobile Application Development
                  </h1>
                  <p className="card-text textjustify">
                    Mobile Applications are getting more popular among
                    businesses and service providers. When potential customers
                    require a product or service, most of the time they look it
                    up on their smartphone rather than on a laptop.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pb-lg-0 pb-4">
              <div className="card box_shadow_ ">
                <div className="position-relative">
                  <div className="position-absolute top-50 bottom-50 pt-5 mt-5 ps-4 translate-middle-y start-0 ">
                    <div className="bg-white p-2 bgicon">
                      {" "}
                      <img alt="..." className="serviceIcons" src={desktop} />
                    </div>
                  </div>
                </div>
                <div className="">
                  <img
                    src={desktop1}
                    className="p-0 m-0 card-img cardWidthF rounded-bottom-0"
                    alt="..."
                  />
                </div>

                <div className="card-body p-3 mb-4">
                  <h1 className="fs-5 fw-semibold mt-4">
                    Desktop Application Development
                  </h1>
                  <p className="card-text textjustify">
                    Desktop applications provide a whole range of business
                    advantages. Desktop applications have better interaction
                    with the user and have better performance with higher
                    security.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card box_shadow_ ">
                <div className="position-relative">
                  <div className="position-absolute top-50 bottom-50 pt-5 mt-5 ps-4 translate-middle-y start-0 ">
                    <div className="bg-white p-2 bgicon">
                      {" "}
                      <img alt="mPair" className="serviceIcons" src={web} />
                    </div>
                  </div>
                </div>
                <div className="">
                  <img
                    src={web1}
                    alt="mPair"
                    className="p-0 m-0 card-img cardWidthF rounded-bottom-0"
                  />
                </div>

                <div className="card-body p-3 mb-4">
                  <h1 className="fs-5 fw-semibold mt-4">
                    Website Design & Development{" "}
                  </h1>
                  <p className="card-text textjustify">
                    Website appearance is one of the most important things to
                    increase the branding of your business. A good-looking
                    website of your company creates a good impression to your
                    clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
      </div>

      <div className="container-lg mb-5 mt-3 pt-5">
        <div className="row helpcard justify-content-center mx-auto">
          <div className="supportCard row p-0">
            <div className="col-lg-2 col-5">
              <img
                src={toufiq}
                alt="Sales | Aminul Asfi | mPair Technologies"
                className="cloud_image"
              />
            </div>
            <div className="col-lg-7 col-7 ">
              <div className="support-contact-text">
                <p className="text-light fw-normal needtxt">
                  Need Help For Cloud Solutions?
                </p>
                <h5 className="fw-normal support-number">+88 01748200588</h5>
              </div>

              <a
                href="contact"
                type="button"
                className="btn support-button2 py-1"
              >
                Contact
              </a>
            </div>
            <div className="col-lg-3 col-0 my-auto buttonblock">
              <a
                href="contact"
                type="button"
                className="btn support-button py-2"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-lg mb-5 text-center">
        <h1 className="text-center py-5">Technology Metrics</h1>
        <div className="row align-items-center">
          <div className="col" width="20%">
            <img src={reactjs} alt="" className="techMetricsLogo" />
          </div>

          <div className="col" width="20%">
            <img src={flutter} alt="" className="techMetricsLogo" />
          </div>
          <div className="col" width="20%">
            <img src={Nodejs} alt="" className="techMetricsLogo" />
          </div>
          <div className="col" width="20%">
            <img src={laravel} alt="" className="techMetricsLogo" />
          </div>
          <div className="col" width="20%">
            <img src={electron} alt="" className="techMetricsLogo" />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default cloud;
