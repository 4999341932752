import React from 'react'
import Nav from '../components/nav'
import Footer from '../components/footer'

const products = () => {
    return (
        <>
            <Nav />
            <Footer />
        </>
    )
}

export default products