import React from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import bgcloud from "../img/resources/designback.png";

import ahis from "../img/resources/portfolio/ahis.png";
import national from "../img/resources/portfolio/national.png";
import parkv from "../img/resources/portfolio/parkv.png";
import amanah from "../img/resources/portfolio/amanah2.png";
import epic from "../img/resources/portfolio/epic.png";
import voltec from "../img/resources/portfolio/voltec.png";

import accuracy from "../img/resources/portfolio/accuracy.png";
import iqbal from "../img/resources/portfolio/itphone.png";
import mbhiyan from "../img/resources/portfolio/mbhuiyan.png";

import ali from "../img/resources/portfolio/aliazam.jpg";
import qurex from "../img/resources/portfolio/qurex.png";
import aromo from "../img/resources/portfolio/aromo.png";

import safa from "../img/resources/portfolio/safa.png";
import foodland from "../img/resources/portfolio/fland.png";
import mhs from "../img/resources/portfolio/mhs.png";

import concorde from "../img/resources/portfolio/concorde.png";
import luster from "../img/resources/portfolio/luster-fit.png";
import sr from "../img/resources/portfolio/sr.png";
const clients = () => {
  return (
    <>
      <Nav />
      <div className="container-fluid client__s">
        <section className="container-md text-center mb-5">
          <div className="servicesCover">
            <h1 className="mb-4 mt-5">
              More Than <span>100K</span> Users
            </h1>

            <div className="servicesText text-center px-lg-5 px-0">
              <p className="font-16 px-lg-5 px-0">
                First impression is very important, and a unique design creates
                good impression. Our creative designers are always committed to
                provide you premium and Professional Design. We follow the
                design process to solve each problem in an innovative way.
              </p>
            </div>
          </div>
        </section>
      </div>

      <div className="container pb-5 mb-5">
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5 heightl">
                <img src={aromo} className="log5 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb3">
                <h6 className="fw-bold  card-title">Aromo Healthcare Ltd.</h6>
                <p className="card-text">
                  A digital platform of healthcare, includes doctor consultancy,
                  medicine mart, and many more. <br />
                  <b> Technology: </b> Next.js, Node.js, Flutter
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5 heightl">
                <img src={qurex} className="log1 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb1">
                <h6 className="fw-bold  card-title">
                  Qrex Diagnostic & Consultation Center
                </h6>
                <p className="card-text">
                  A smart Doctor Appointment System that provides patient queue
                  management including real-time notification and a dedicated
                  Mobile App.
                  <br />
                  <b> Technology: </b> Vue.js, Node.js
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5 heightl">
                <img src={ahis} className="log1 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb">
                <h6 className="fw-bold  card-title">
                  Al-Hidaayah International School
                </h6>
                <p className="card-text">
                  Complete School Management Software combined with School ERP &
                  Learning Management System. <br />
                  <b> Technology: </b> React.js, Node.js, React Native
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5 heightl">
                <img src={mbhiyan} className="log3 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb1">
                <h6 className="fw-bold  card-title">
                  M. Bhuiyan Construction LLC
                </h6>
                <p className="card-text">
                  Project Management Software includes all necessary features
                  like Project tracking, Accounts & Resource Management,
                  Supplier Management, etc.
                  <br /> <b>Technology:</b> React.js, Node,js
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5  heightl">
                <img src={parkv} className="log2 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb2">
                <h6 className="fw-bold  card-title">Parkview Hospital Ltd.</h6>
                <p className="card-text">
                  A smart Doctor Appointment System that provides patient queue
                  management including real-time notification and a dedicated
                  Mobile App.
                  <br /> <b> Technology:</b> Vue.js, Node,js, Flutter, MySQL
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5  heightl">
                <img src={amanah} className="log2 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb3">
                <h6 className="fw-bold  card-title">Amanah Matrimony</h6>
                <p className="card-text">
                  A personalized & user-friendly matrimonial website that serves
                  the specific needs and preferences of our client. <br />{" "}
                  <b>Technology:</b> React.js, Node,js
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5  heightl">
                <img src={epic} className="log5 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb3">
                <h6 className="fw-bold  card-title">Epic Properties Ltd.</h6>
                <p className="card-text">
                  Customized Website Design and maintainance for a well-known
                  Real State Company in Chittagong, Bangladesh.
                  <br />
                  <b>Technology:</b> WordPress
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5  heightl">
                <img src={accuracy} className="log2 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb2">
                <h6 className="fw-bold  card-title">
                  Accuracy Engineering Ltd.
                </h6>
                <p className="card-text">
                  Customized WordPress Website Design and Development for a
                  trading company in Chittagong, Bangladesh. <br />
                  <b>Technology:</b> WordPress Customization
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5 heightl">
                <img src={luster} className="log4 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb3">
                <h6 className="fw-bold  card-title">Luster Fit</h6>
                <p className="card-text">Logo Design</p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5  heightl">
                <img src={iqbal} className="log2 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb">
                <h6 className="fw-bold  card-title">I&T Mobile Phones LLC.</h6>
                <p className="card-text">
                  Customized Ecommerce Website Design and Development for a
                  wholesale Mobile Shop in Dubai, UAE. <br />
                  <b>Technology: </b> React.js & Node.js
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5 heightl">
                <img src={foodland} className="log3 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb">
                <h6 className="fw-bold  card-title">
                  Foodland Farm Intl. Halal market
                </h6>
                <p className="card-text">
                  Customized Ecommerce Website Design and Development for a
                  Super SHop. <br />
                  <b> Technology: </b> React.js & Node.js
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5 heightl">
                <img src={voltec} className="log2 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb1">
                <h6 className="fw-bold  card-title">Voltec Engineering LTD.</h6>
                <p className="card-text">
                  Customized WordPress Website Design and Development for a
                  trading company in Chittagong, Bangladesh.
                  <br /> <b> Technology:</b> WordPress Customization
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5 heightl">
                <img src={sr} className="log3 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb2">
                <h6 className="fw-bold  card-title">Sr Travels & Tour</h6>
                <p className="card-text">
                  Complete Travel Agency Back Office Management Software for SR
                  Travels and Tours.
                  <br /> <b> Technology: </b> React.js & Node.js
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5 heightl">
                <img src={concorde} className="log5 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb3">
                <h5 className="fw-bold  card-title">Concorde Intl.</h5>
                <p className="card-text">
                  Complete Travel Agency Back Office Management Software for
                  Concorde International.
                  <br /> <b> Technology: </b> React.js & Node.js
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center  heightl p-5">
                <img src={national} className="log4 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb3">
                <h6 className="fw-bold  card-title">
                  National Group Bangladesh
                </h6>
                <p className="card-text">
                  Customized Company Profile Website Design and Development.{" "}
                  <br /> <b> Technology: </b> PHP,HTML & CSS{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5 heightl">
                <img src={mhs} className="log3 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb2">
                <h6 className="fw-bold  card-title">Mahdee Hasan Studio</h6>
                <p className="card-text">
                  Subscription-based Website Development for Mahadi Hasan
                  Studio; an Islamic recording and publishing label.
                  <br /> <b> Technology: </b> React.js & Node.js
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5 heightl">
                <img src={ali} className="log6 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb3">
                <h6 className="fw-bold  card-title">Ali Azam School</h6>
                <p className="card-text">
                  Membership Website Development for Ali Azam School & College
                  Hundred Years Celebration Event.
                  <br /> <b> Technology: </b>React.js & Node.js
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 p-0 ">
            <div className="box_shadow_1">
              <div className="text-center p-5 heightl">
                <img src={safa} className="log3 text-center" alt="..." />
              </div>

              <div className="card-body rounded-0 p-3 colbgb">
                <h6 className="fw-bold  card-title">Safa Fashion House</h6>
                <p className="card-text">
                  This is a longer card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer. <br /> <b> Technology: </b> Wordpress
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default clients;
