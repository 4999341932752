import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Nav from "../components/nav";
import Footer from "../components/footer";

function SingleBlog() {
  const { id } = useParams();
  const [blogData, setBlogData] = useState();
  const [blogsData, setBlogsData] = useState([]);
  const [filter, setFilter] = useState("");
  const [update, setUpdate] = useState([]);
  const handleFilterChange = (event) => {
    setFilter(event.target.value.toUpperCase());
  };

  function recieve() {
    fetch("https://server.mpairtech.com/mpserver/blogs_recieve", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setBlogsData(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    recieve();
  }, []);

  const filteredBlogs = blogsData.filter((blog) =>
    blog.blogTitle.toUpperCase().includes(filter)
  );

  function fetchBlogData() {
    const data = new FormData();
    data.append("blogId", id);
    fetch(`https://server.mpairtech.com/mpserver/blogs_recieveByID`, {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setBlogData(data.message[0]);
        setUpdate(update + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchBlogData();
  }, [update]);

  const formatDate = (dateTime) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateTime).toLocaleDateString([], options);
  };

  return (
    <>
      <Nav />

      <section>
        <div className="container my-5 p-5">
          <div className="row">
            <div className="col-lg-8">
              <h1>{blogData?.blogTitle}</h1>
              <p style={{ fontSize: "12px" }}> {formatDate(blogData?.date)}</p>
              <div>
                <img
                  style={{
                    height: "320px",
                    width: "600px",
                    objectFit: "contain",
                  }}
                  className="blog-head"
                  src={`https://server.mpairtech.com/public/image/${blogData?.blogImage}`}
                  alt={blogData?.blogTitle}
                />
              </div>

              <p className="mt-5 text_justify">
                {" "}
                <div
                  dangerouslySetInnerHTML={{ __html: blogData?.description }}
                />
              </p>
            </div>
            {/*  */}

            {/*  */}
            <div className="col-lg-4 rounded-2">
              <div className="bg-light py-2 px-2">
                <div className="div p-3 ps-0">POPULAR BLOGS</div>
                <ul className="list-group rounded-0">
                  {filteredBlogs.map((blog) => (
                    <li key={blog.id} className="list-group-item mb-2">
                      <Link className="txdNone" to={`/blog/${blog.id}`}>
                        {blog.blogTitle}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default SingleBlog;
