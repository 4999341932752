import React from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import fast from "../img/fast-response.png";
import telephone from "../img/telephone.png";
import complain from "../img/complain.png";
import { useState } from "react";
const Contact = () => {
  const [mailSubject, setMailSubject] = useState("");
  const [mailBody, setMailBody] = useState("");
  const [sender, setSender] = useState("");
  const [bot, setBot] = useState("");

  function submit(e) {
    e.preventDefault();
    if (bot !== "") {
      return false;
    }

    const data = new FormData();
    data.append("sender", sender);
    data.append("mailSubject", mailSubject);
    data.append("mailBody", mailBody);

    fetch(process.env.REACT_APP_API_URL + "/mail", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Nav />
      <section className="container-fluid contactCover">
        <div className="servicesCove container-lg">
          <h1 className="my-3 text-light pt-5"> Contact Us</h1>

          <Link
            type="button"
            to=""
            target="_blank"
            className="btn  border text-white rounded-0 contact-button py-2 my-3 "
          >
            Chat with mPair Support
          </Link>
        </div>
      </section>

      <section className="contact-form mb-4 pt-5" id="contact">
        <div className="container-lg">
          <h2 className="mt-5 mb-4">Pair With Us</h2>
          <div className="row bgCol3 p-lg-5 p-md-5 p-3">
            <div className="col-lg-6 mb-5">
              <h5>
                {" "}
                <img className="tel_phone" src={telephone} alt="" /> Mobile
                Numbers
              </h5>
              <p className="text-muted">
                Available: 10:00 AM (GMT+6) - 07:00 PM (GMT+6)
              </p>
              <br />
              <h6 className="">
                General Inquiries:{" "}
                <span className="text-muted">+88 01713368297</span>
              </h6>
              <h6 className="">
                Technical Support:{" "}
                <span className="text-muted">+88 01713368296</span>
              </h6>
              <h6 className="">
                Sales: <span className="text-muted">+88 01748200588</span>
              </h6>

              <br />
              <h5>
                {" "}
                <img className="e-mail" src={fast} alt="" />
                E-mail Support
              </h5>
              <h6 className="">
                General Inquiries:{" "}
                <span className="text-muted">info@mpairtech.com</span>
              </h6>
              <h6 className="">
                Sales: <span className="text-muted">sales@mpairtech.com</span>
              </h6>
            </div>
            <div className="col-lg-6">
              <h5>
                {" "}
                <img className="e-mail" src={complain} alt="" /> Speak with
                sales through email!
              </h5>
              <p>
                Select your service area - you will be promped to provide
                contact details and a message, then a sales representative will
                contact you.
              </p>

              <form onSubmit={submit}>
                <select
                  className="form-select mb-4 rounded-0"
                  aria-label="Service area select"
                  name="survice"
                  onChange={(e) => {
                    setMailSubject(e.target.value);
                  }}
                  required
                >
                  <option value="" selected>
                    Select Your Service Area
                  </option>
                  <option>Embedded & Robotics</option>
                  <option>Cloud & Software</option>
                  <option>Professional Design</option>
                </select>
                <input
                  type="email"
                  className="form-control rounded-0 mb-4"
                  placeholder="Your Business Email"
                  onChange={(e) => {
                    setSender(e.target.value);
                  }}
                  required
                />
                <textarea
                  className="form-control rounded-0 mb-4"
                  rows="3"
                  placeholder="Describe your requirements here..."
                  onChange={(e) => {
                    setMailBody(e.target.value);
                  }}
                  required
                ></textarea>

                <input
                  type="text"
                  className="form-control p-0 m-0 invisible"
                  placeholder="Your Business Email"
                  onChange={(e) => {
                    setBot(e.target.value);
                  }}
                />

                <div className="d-flex">
                  <button
                    id="for_bot"
                    type="submit"
                    href="contact"
                    className="btn header-button py-2 my-3 mx-auto text-white"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid contactFAQ mb-4 p-0">
        <div className="container-lg">
          <h2 className="mt-5 mb-4">How Can We Help You ?</h2>

          <div
            className="accordion accordion-flush p-4 pb-0 bgCol3"
            id="faqAccord"
          >
            <div className="accordion-item row justify-content-between align-items-center border-0 ">
              <div className="col-lg-11 col-11">
                <h5 className="def-font-color p-2 m-0">
                  I need to know more about your services
                </h5>
              </div>
              <div className="accordion-icon col-lg-1 col-1">
                <i
                  className="fa-solid collapsed"
                  data-bs-target="#faqOne"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                ></i>
              </div>

              <div
                className="accordion-collapse collapse"
                id="faqOne"
                data-bs-parent="#faqAccord"
              >
                <div className="accordion-body">
                  <p className="">
                    Team mPair is always committed to assure you the best and
                    innovative solution by introducing new technology to
                    accelerate the world's transition to sustainable future.
                  </p>
                  <p>
                    Cloud & Software Solution
                    <br />
                    • Website Design & Development <br />
                    • Software Development <br />
                    • Mobile Application (Android & iOS) Development <br />
                  </p>
                  <p>
                    Embedded & Industrial Solution <br />
                    • Industrial Automation <br />
                    • Robotics & IoT <br />
                    • PCB Design <br />
                  </p>
                  <p>
                    Professional Design Solution <br />
                    • Product Design <br />
                    • UI & UX Design <br />
                    • Motion Graphic Design & Animation <br />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="accordion accordion-flush p-4 pb-0 bgCol3"
            id="faqAccord"
          >
            <div className="accordion-item row justify-content-between align-items-center border-0 ">
              <div className="col-lg-11 col-11">
                <h5 className="def-font-color p-2 m-0">
                  I want a consultant about my project
                </h5>
              </div>
              <div className="accordion-icon col-lg-1 col-1">
                <i
                  className="fa-solid collapsed"
                  data-bs-target="#faqTwo"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                ></i>
              </div>

              <div
                className="accordion-collapse collapse"
                id="faqTwo"
                data-bs-parent="#faqAccord"
              >
                <div className="accordion-body">
                  <p className="">
                    Team mPair is always here for you with the best consultancy
                    service. Get both free and professional project consultancy
                    services by experts. Appointment for a consultancy is just
                    one click away.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="accordion accordion-flush p-4 pb-0 bgCol3"
            id="faqAccord"
          >
            <div className="accordion-item row justify-content-between align-items-center border-0 ">
              <div className="col-lg-11 col-11">
                <h5 className="def-font-color p-2 m-0">
                  I need a Quotation for my project
                </h5>
              </div>
              <div className="accordion-icon col-lg-1 col-1">
                <i
                  className="fa-solid collapsed"
                  data-bs-target="#faqThree"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                ></i>
              </div>

              <div
                className="accordion-collapse collapse"
                id="faqThree"
                data-bs-parent="#faqAccord"
              >
                <div className="accordion-body">
                  <p className="">
                    We are glad to hear that you would like to pair with us to
                    get the best and innovative solution. We are here to help
                    you with your project. Please provide us details
                    requirements of the project.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="accordion accordion-flush p-4 pb-0 bgCol3"
            id="faqAccord"
          >
            <div className="accordion-item row justify-content-between align-items-center border-0 ">
              <div className="col-lg-11 col-11">
                <h5 className="def-font-color p-2 m-0">
                  What technology do you use for web and mobile application
                  development?
                </h5>
              </div>
              <div className="accordion-icon col-lg-1 col-1">
                <i
                  className="fa-solid collapsed"
                  data-bs-target="#faqFour"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                ></i>
              </div>

              <div
                className="accordion-collapse collapse"
                id="faqFour"
                data-bs-parent="#faqAccord"
              >
                <div className="accordion-body">
                  <p className="">
                    We use the latest trendy technologies for our development
                    process. For front-end development, we use HTML, CSS,
                    Javascript, Vue.js, and React.js. For back-end development,
                    we use PHP, Laravel, and Node.js. For mobile application
                    development, we use React Native and Flutter. For desktop
                    app development, we use Java and Electron.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion accordion-flush p-4 bgCol3" id="faqAccord">
            <div className="accordion-item row justify-content-between align-items-center border-0 ">
              <div className="col-lg-11 col-11">
                <h5 className="def-font-color p-2 m-0">
                  What will be the support services after the development is
                  complete?
                </h5>
              </div>
              <div className="accordion-icon col-lg-1 col-1">
                <i
                  className="fa-solid collapsed"
                  data-bs-target="#faqFive"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                ></i>
              </div>

              <div
                className="accordion-collapse collapse"
                id="faqFive"
                data-bs-parent="#faqAccord"
              >
                <div className="accordion-body">
                  <p className="">
                    We provide 6 months of free maintenance support after the
                    development is complete. You can also get further
                    development services if you need to implement any new
                    features.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Contact;
