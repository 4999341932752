import Nav from "../components/nav";
import Footer from "../components/footer";
import sales from "../img/career/sales.png";
import careers from "../img/career/ChooseUS.png";
import clock from "../img/career/time.png";
import user from "../img/career/user.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const Career = () => {
  let navigate = useNavigate();
  // const [selectedViewVacancy, setSelectedViewVacancy] = useState(null);
  const [vacanciesData, setVacanciesData] = useState([]);
  const [cvImage, setCvImage] = useState(null);
  function recieve() {
    fetch("https://server.mpairtech.com/mpserver/vacancies_recieve", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setVacanciesData(res.message);
        console.log(vacanciesData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    recieve();
  }, []);

  // function submit() {
  //   const data = new FormData();
  //   data.append("cvImage", cvImage);

  //   fetch("https://server.mpairtech.com/mpserver/cvSubmit", {
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       navigate("/career");
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }
  return (
    <>
      <Nav />

      <div className="container-fluid">
        <section className="container-md text-start mb-5">
          <div className="servicesCover">
            <h1 className="mb-4 mt-5">Careers at mPair Technologies</h1>

            <div className="servicesText">
              <p className="servicesSubText">
                <i className="blockquote-footer">
                  "Choose a job you love, and you will never have to work a day
                  in your life" - Confucius
                </i>
              </p>
            </div>
          </div>
        </section>
      </div>

      <section>
        <div className="container mb-5">
          <div className="row mx-auto">
            {" "}
            {vacanciesData?.map((vacancy) => (
              <div key={vacancy.id} className="col-lg-6 col-12 col-md-12 mb-4">
                <div className="careerCard p-0 m-0">
                  <div className="card-body careerCardBody bg-white p-0">
                    <div className="row px-0">
                      <div className="col-lg-7 col-12 col-md-7 p-1 ps-5">
                        <img
                          src={sales}
                          alt="#"
                          className="careerCardIcon mt-5 mb-3"
                        />

                        <h5 className="text-dark pb-3">{vacancy.vacancyFor}</h5>
                        <p className="blockquote-footer">
                          {vacancy.experience}
                        </p>
                      </div>
                      <div className="col-lg-5 col-12 col-md-5 p-5 py-0">
                        <p className="text-muted mb-1 mt-5">
                          <img
                            className="pe-1 mb-1"
                            src={clock}
                            style={{ height: "16px" }}
                            alt=""
                          />
                          {vacancy.jobType}
                        </p>
                        <p className="text-muted">
                          <img
                            className="pe-1 mb-1"
                            src={user}
                            style={{ height: "17px" }}
                            alt=""
                          />
                          {vacancy.vacancys} Person
                        </p>
                      </div>
                    </div>

                    <div className="row bg-white down_bor">
                      <div className="col-lg-12">
                        <div className="d-flex">
                          <Link
                            type="button"
                            className="btn btn-outline-secondary my-2"
                            to={`/vacancies/${vacancy.id}`}
                          >
                            Apply Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="container-fluid careerAd">
        <div className="row align-items-center">
          <div className="col-lg-6 col-12 col-md-7 p-5">
            <div className="careerAdText">
              <p className="fs-3 fw-lighter text-light">Why you choose </p>
              <h1 className="text-light">mPair Career?</h1>
              <div className="mt-4 txt_col">
                "
                <p>
                  <span>
                    <i className="fa-solid fa-circle-arrow-right"></i>Flexible
                    Work Environment
                  </span>
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-circle-arrow-right"></i>Adopting
                    Trendy Technologies
                  </span>
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-circle-arrow-right"></i>Team Work
                    Facilities
                  </span>
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-circle-arrow-right"></i>
                    Experienced Team Lead
                  </span>
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-circle-arrow-right"></i>
                    International Standard Working Hour
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 col-md-5 text-center">
            <div className="productAdImgPos py-5">
              <img src={careers} alt="" className="productAdImg" />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Career;
