import React, { useState, useEffect } from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import asif from "../img/aboutUs/asif.png";
import eshan from "../img/aboutUs/eshan.png";
import fakwer from "../img/aboutUs/fakwer.jpg";
import hasib from "../img/aboutUs/hasib.jpg";
import irfath from "../img/aboutUs/irfath.jpg";
import mainul from "../img/aboutUs/mainul.png";
import officeimage from "../img/aboutUs/webabout.jpg";
import quote from "../img/aboutUs/quote.png";
import sarfaraz from "../img/aboutUs/sarfaraz.jpg";
import shihab from "../img/aboutUs/shihab.jpg";
import toufiq from "../img/aboutUs/toufiq.jpg";
import ensure from "../img/ensure.png";
import time from "../img/time.png";
import pdf from "../img/aboutUs/mPairTechnologiesServiceProfile.pdf";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const [text, setText] = useState("");
  const [showCursor, setShowCursor] = useState(true);

  const speed = 120;

  useEffect(() => {
    const words = ["Innovation", "Revolution", "Transformation"];
    let currentText = "";
    let currentIndex = 0;

    const typeText = () => {
      if (currentIndex < words.length) {
        currentText = words[currentIndex].slice(0, currentText.length + 1);
        setText(currentText);

        if (currentText === words[currentIndex]) {
          setShowCursor(false);

          setTimeout(() => {
            setShowCursor(true);
            eraseText();
          }, 2000);
        } else {
          setTimeout(typeText, speed);
        }
      } else {
        currentIndex = 0;
        typeText();
      }
    };

    const eraseText = () => {
      if (currentText.length > 0) {
        currentText = currentText.slice(0, currentText.length - 1);
        setText(currentText);

        setTimeout(eraseText, speed);
      } else {
        currentIndex++;
        setTimeout(typeText, speed);
      }
    };

    typeText();
  }, []);

  return (
    <>
      <Nav />

      <section className="container-fluid aboutCover1">
        <div className="container-lg">
          <h1 className="mb-4 text-light">About mPair Technologies</h1>

          <p className="text-white about-text">
            mPair Technologies Ltd is involved in designing sustainable
            technologies and assuring sustainability in solutions to the present
            and future challenges of your business.
          </p>
          <Link
            type="button"
            to={pdf}
            target="_blank"
            className="btn text-white rounded-0 header-button py-2 my-lg-4 my-md-4 my-0 contact-button"
          >
            Company Profile
          </Link>
        </div>
      </section>

      <section className="mobile2">
        <div className="container my-5">
          <div className="row justify-content-center my-5">
            <div className="col-lg-12 text-center mt-lg-5">
              <h1 className="pname-type mt-4">
                Creating Ideas For <br className="vanish_pc" />{" "}
                <span className="type-text">{text}</span>
                {showCursor && <span className="cursor-text">|</span>}
              </h1>
            </div>
            <div className="col-lg-10 text-center my-3 mb-5">
              <p className="pname3 mb-5">
                The imminent fourth industrial revolution promises a simpler,
                better, and more productive life through innovative ideas.
                Converting a simple thought into a revolution is crucial, but
                flawless execution is equally vital. mPair Technologies strives
                to ensure superior solutions for future challenges, advancing
                the technological revolution for societal betterment.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid justify-content-center team-text-box px-0 dextop">
        <div className="d-flex">
          <div className="ps-5 pt-4 ofc_txt">
            <img className="ps-5 quote-icon" src={quote} alt="" />
            <p className="pname3 ps-5 pe-4">
              Team collaboration is pivotal for project success. It enables
              diverse perspectives, maximizes collective strengths, and fosters
              innovative problem-solving. Effective communication, clear roles,
              and a shared vision are vital components that enhance project
              work, ensuring efficiency and superior outcomes.
            </p>
          </div>

          <div className="">
            <img className="text-end office_img" src={officeimage} alt="" />
          </div>
        </div>
      </section>
      <br />
      <section className="container-fluid justify-content-center team-text-box px-0 mobile ">
        <div className="p-4 pt-5">
          <img className="quote-icon" src={quote} alt="" />
          <p className="pname3 p-4 text-center">
            Team collaboration is pivotal for project success. It enables
            diverse perspectives, maximizes collective strengths, and fosters
            innovative problem-solving. Effective communication, clear roles,
            and a shared vision are vital components that enhance project work,
            ensuring efficiency and superior outcomes.
          </p>
        </div>

        <div className="">
          <img className="text-end w-100" src={officeimage} alt="" />
        </div>
      </section>

      <section className=" my-5">
        <div className="container-fluid  py-5 mt-5">
          <h1 className="text-center ">Why Choose Us?</h1>
          <p
            className="text-center mt-2 mb-lg-5"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            We offer inventive solutions, cutting-edge technology, and adaptable
            strategies to ensure efficient execution.
          </p>
          <div className="container rounded">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-12 feature py-3 bg-light border-end">
                <div className="py-3">
                  <img src={time} alt="mPair" className="service-icon" />
                  <p className="featureHeader mb-1"> On Time & Within Budget</p>
                  <p className="featureText mt-2">
                    Delivering excellence on time and within budget. We
                    prioritize your timeline and financial goals to ensure
                    successful outcomes.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-12 feature bg-light py-3 ">
                <div className="  py-3">
                  <img src={ensure} alt="mPair" className="service-icon" />
                  <p className="featureHeader mb-1">
                    Quality Service Assurance
                  </p>
                  <p className="featureText mt-2">
                    Experience quality service assurance with our dedicated
                    team. We uphold the highest standards to exceed your
                    expectations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid aboutOfficials mt-5">
        <div className="aboutOfficials-content container-lg">
          <h1 className="text-center mb-3">Our Officials</h1>
          <p className="text-center mb-5">
            Our dedicated team of professionals delivers outstanding service and
            support, guaranteeing client satisfaction and success.
          </p>
          <div className="row mt-3">
            <div className="col-lg-3 col-md-6 text-center">
              <img src={eshan} alt="" className="officialsImg" />
              <div className="officialsText text-center ">
                <p className="px-2 pt-1 m-0">
                  <strong>Syed Mainul Islam</strong>
                </p>
                <p className="px-2 pb-1">Chairman & CEO</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <img src={mainul} alt="" className="officialsImg" />
              <div className="officialsText text-center ">
                <p className="px-2 pt-1 m-0">
                  <strong>Md. Mainul Hossen</strong>
                </p>
                <p className="px-2 pb-1">Managing Director</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 text-center">
              <img src={asif} alt="" className="officialsImg" />
              <div className="officialsText text-center ">
                <p className="px-2 pt-1 m-0">
                  <strong>Asiful Alam</strong>
                </p>
                <p className="px-2 pb-1">System Engineer</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <img src={irfath} alt="" className="officialsImg" />
              <div className="officialsText text-center ">
                <p className="px-2 pt-1 m-0">
                  <strong>Irfath Chowdhury</strong>
                </p>
                <p className="px-2 pb-1">Server Administrator</p>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-3 col-md-6 text-center">
              <img src={hasib} alt="" className="officialsImg" />
              <div className="officialsText text-center ">
                <p className="px-2 pt-1 m-0">
                  <strong>Md. Hasibul Hamid</strong>
                </p>
                <p className="px-2 pb-1">Front-End Developer</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 text-center">
              <img src={toufiq} alt="" className="officialsImg" />
              <div className="officialsText text-center ">
                <p className="px-2 pt-1 m-0">
                  <strong>Md. Taufiqur Rahman</strong>
                </p>
                <p className="px-2 pb-1">Sales & Marketing</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 text-center">
              <img src={fakwer} alt="" className="officialsImg" />
              <div className="officialsText text-center ">
                <p className="px-2 pt-1 m-0">
                  <strong>Md. Fakwer Uddin</strong>
                </p>
                <p className="px-2 pb-1">Jr. IoT Developer</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 text-center">
              <img src={shihab} alt="" className="officialsImg" />
              <div className="officialsText text-center ">
                <p className="px-2 pt-1 m-0">
                  <strong>Md. Shihab Hossain</strong>
                </p>
                <p className="px-2 pb-1">Jr. App Developer</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 text-center">
              <img src={sarfaraz} alt="" className="officialsImg" />
              <div className="officialsText text-center ">
                <p className="px-2 pt-1 m-0">
                  <strong>Md. Sarfaraj Rahman</strong>
                </p>
                <p className="px-2 pb-1">Jr. Front-End Developer</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid aboutLocation bg-light aboutLocation-img">
        <div className="container-lg aboutLocation-content mt-4 py-5">
          <h1 className="mb-4 pseudoEl1">Our Location</h1>
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <h3 className="text-dark">Head Office</h3>
              <p className="text-dark">
                M.B. Complex <br />
                90 High Level Road, <br />
                Chattogram, Bangladesh
              </p>
              <Link
                type="button"
                to="/contact"
                className="btn header-button text-dark py-2"
                id="contact_sec"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default AboutUs;
