import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Nav from "../components/nav";
import Footer from "../components/footer";

const AllblogView = () => {
  const [blogsData, setBlogsData] = useState([]);
  const [filter, setFilter] = useState("");

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toUpperCase());
  };

  function recieve() {
    fetch("https://server.mpairtech.com/mpserver/blogs_recieve", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setBlogsData(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    recieve();
  }, []);

  const filteredBlogs = blogsData.filter((blog) =>
    blog.blogTitle.toUpperCase().includes(filter)
  );

  return (
    <>
      <Nav />

      <section className="home-blog">
        <div className="container py-5">
          <h1 className="text-center">Article For Your Business</h1>

          <div className="d-flex justify-content-center">
            <form className="row g-2 my-4 mb-2">
              <div className="d-flex align-items-center border p-2 rounded-5">
                <i className="me-3 fa fa-search text-secondary fs-4 ps-2 pt-1"></i>
                <input
                  type="text"
                  onChange={handleFilterChange}
                  placeholder="Search for blog titles.."
                  title="Type in a title"
                  className="border-0 form-control shadow-none outline-0 px-lg-5 px-md-5 px-4 mx-0 mx-lg-5 mx-md-5"
                  id="inputKey"
                />
              </div>
            </form>
          </div>

          <div className="row my-4 ">
            {filteredBlogs.map((blog) => (
              <div key={blog.id} className="col-lg-4 p-4 ">
                <div className=" box_shadow_  rounded blog_height">
                  <div className="d-flex">
                    <img
                      className="blog-head w-100"
                      src={
                        "https://server.mpairtech.com/public/image/" +
                        blog.blogImage
                      }
                      alt={blog.blogTitle}
                    />
                  </div>
                  <div className="p-3">
                    <p className="mb-1 font-18 mt-3">{blog.blogTitle}</p>
                    <p className="mb-1 font-12 text-muted">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${blog.description.slice(0, 100)} ${
                            blog.description.length > 100 ? "..." : ""
                          }`,
                        }}
                      />
                    </p>
                    <Link
                      className="m-0 p-0 featureTexte "
                      to={`/blog/${blog.id}`}
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AllblogView;
