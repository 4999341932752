import React from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import apple from "../img/docAppoint/apple.png";
import google from "../img/docAppoint/google.png";
import banner from "../img/docAppoint/docbanner.png";
import doca3 from "../img/docAppoint/doca-01.png";
import doca2 from "../img/docAppoint/doca-02.png";
import doca1 from "../img/docAppoint/doca-03.png";
import doca4 from "../img/docAppoint/doca-04.png";

const docappoint = () => {
  return (
    <>
      <Nav />
      <section className="docBanner pt-5">
        <div className="container pt-sm-0">
          <div className="row mt-5">
            <div className="col-lg-6">
              <h1 className=" text-dark">
                Improve Doctor
                <br /> Appointment Experience <br /> With <span></span>
                <span className="product-name-text">docAppoint</span>
              </h1>

              <h6 className="text-dark">
                Long queues make customer unsatisfied. Replace traditional
                appointment process with docAppoint; a robust physician
                appointment system.
              </h6>
              <br />
              <p className="p-0 m-0 text-secondary">Available For</p>
              <div className="d-flex mbApp">
                <div className="pe-3">
                  {" "}
                  <img src={google} alt="" />
                </div>
                <div>
                  {" "}
                  <img src={apple} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div className="docBan">
                <img src={banner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="docItem">
        <div className="container p-5 docitem">
          <h1 className="text-center mb-5">
            {" "}
            Connecting Healthcare Consultant with Patient
          </h1>
          <div className="row text-center">
            <div className="col-lg-3 box_shadow mb-lg-0 mb-md-3 mb-5">
              <div className="p-5 box_2_shadow_1">
                <img src={doca1} alt="" /> <h5>Nearby Hospital</h5>
                <p className="text-center">
                  Schedule doctor appointments from nearby healthcare centre
                </p>
              </div>
            </div>
            <div className="col-lg-3   box_shadow mb-lg-0 mb-md-3 mb-5">
              <div className="box_2_shadow_1 p-5">
                <img src={doca2} alt="" /> <h5>Health Assistant</h5>
                <p className="text-center">
                  Live talk with docAppoint health assistant to express your
                  symptoms
                </p>
              </div>
            </div>
            <div className="col-lg-3  box_shadow mb-lg-0 mb-md-3 mb-5 ">
              <div className="p-5 box_2_shadow_1">
                <img src={doca3} alt="" /> <h5>Appointments Report</h5>
                <p className="text-center">
                  All time appointment report archive for healthcare authority
                </p>
              </div>
            </div>
            <div className="col-lg-3  box_shadow mb-lg-0 mb-md-3 mb-5">
              <div className="p-5 box_2_shadow_1">
                <img src={doca4} alt="" /> <h5>Doctors Report</h5>
                <p className="text-center">
                  Generate doctors report for informed sevice improvement
                  decisions
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default docappoint;
