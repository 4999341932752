import React, { useState, useEffect } from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import sales from "../img/career/sales.png";
import { useNavigate, useParams } from "react-router-dom";
import careers from "../img/career/ChooseUS.png";
import { Toast } from "bootstrap";
const Vacancies = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedViewVacancy, setSelectedViewVacancy] = useState(null);
  const [vacanciesData, setVacanciesData] = useState([]);
  const [cvImage, setCvImage] = useState(null);

  function receiveVacancies() {
    const data = new FormData();
    data.append("vacanciesId", id);
    fetch("https://server.mpairtech.com/mpserver/vacancies_recieveByID", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setVacanciesData(res.message[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    receiveVacancies();
  }, []);

  function handleApply() {
    const data = new FormData();
    data.append("cvImage", cvImage);
    data.append("type", vacanciesData?.vacancyFor);

    fetch("https://server.mpairtech.com/mpserver/cvSubmit", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        showToast("Thank You. Your CV has submitted.");
        const inputFile = document.getElementById("modal-cv");
        inputFile.value = "";
        setCvImage(null);
        // navigate("/career");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // function showToast(message) {
  //   const toastBody = document.getElementById("toast-body");
  //   toastBody.textContent = message;

  //   const toast = new Toast(document.getElementById("liveToast"));
  //   toast.show();
  // }

  function showToast(message) {
    const toastBody = document.getElementById("toast-body");
    toastBody.textContent = message;

    const toast = new Toast(document.getElementById("liveToast"));
    toast.show();
  }

  // function handleApply2() {
  //   showToast("Thank You. Your CV has submitted.");
  //   const inputFile = document.getElementById("modal-cv");
  //   inputFile.value = "";
  //   setCvImage(null);
  // }
  return (
    <>
      <Nav />
      <div
        style={{ paddingLeft: "250px", paddingRight: "250px" }}
        className="container py-5 mb-5"
      >
        <div>
          <div className="row">
            <div className="col-lg-8">
              <img src={sales} alt="" className="modalIcon mb-2" />
            </div>
            <div className="col-lg-4 ps-5">
              <p className="text-muted mb-2">{vacanciesData.jobType}</p>
              <p className="text-muted mb-2">{vacanciesData.vacancys} Person</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h5 className="modal-title" id="modalTitle">
                {vacanciesData.vacancyFor}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <p className="text-muted">{vacanciesData.experience}</p>
            </div>
          </div>
          <hr className="mt-2" />
          <div className="row">
            <div className="col-lg-12">
              <div
                dangerouslySetInnerHTML={{
                  __html: vacanciesData.responsibility,
                }}
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="fw-bold col-lg-4 col-12 col-md-12">
              <label htmlFor="modal-cv" className="form-label pt-2">
                Drop Your CV Here <i className="fa-solid fa-chevron-right"></i>
              </label>
            </div>
            <div className="col-lg-6 col-9 col-md-9">
              <input
                type="file"
                className="form-control border-0 rounded-0 modalInput"
                id="modal-cv"
                name="cv"
                accept="application/pdf"
                onChange={(e) => {
                  const selectedFile = e.target.files[0];
                  if (selectedFile) {
                    const fileExtension = selectedFile.name
                      .split(".")
                      .pop()
                      .toLowerCase();
                    if (fileExtension !== "pdf") {
                      showToast("Please select a PDF file.");
                      e.target.value = ""; // Clear the input
                      return;
                    }
                    setCvImage(selectedFile);
                  } else {
                    setCvImage(null);
                  }
                }}
                required
              />
            </div>
            <div className="col-lg-2 col-3 col-md-3">
              <button
                className="btn modalBtn1 rounded-0"
                name="cv-submit"
                onClick={handleApply}
                disabled={!cvImage} // Disable the button if cvImage is falsy
              >
                Apply Now
              </button>

              <div className="toast-container position-fixed bottom-50 end-0 p-3">
                <div
                  id="liveToast"
                  className="toast"
                  role="alert"
                  aria-live="assertive"
                  aria-atomic="true"
                >
                  <div className="toast-header">
                    <strong className="me-auto">File Upload</strong>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="toast"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="toast-body" id="toast-body"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="container-fluid careerAd">
        <div className="row align-items-center">
          <div className="col-lg-6 col-12 col-md-7 p-5">
            <div className="careerAdText">
              <p className="fs-3 fw-lighter text-light">Why you choose </p>
              <h1 className="text-light">mPair Career?</h1>
              <div className="mt-4 txt_col">
                <p>
                  <span>
                    <i className="fa-solid fa-circle-arrow-right"></i>Flexible
                    Work Environment
                  </span>
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-circle-arrow-right"></i>Adopting
                    Trendy Technologies
                  </span>
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-circle-arrow-right"></i>Team Work
                    Facilities
                  </span>
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-circle-arrow-right"></i>
                    Experienced Team Lead
                  </span>
                </p>
                <p>
                  <span>
                    <i className="fa-solid fa-circle-arrow-right"></i>
                    International Standard Working Hour
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 col-md-5 text-center">
            <div className="productAdImgPos py-5">
              <img src={careers} alt="" className="productAdImg" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Vacancies;
