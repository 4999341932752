import React from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import apple from "../img/docAppoint/apple.png";
import google from "../img/docAppoint/google.png";
import banner from "../img/flydocx/financeUi.png";
import flydoc1 from "../img/flydocx/fly(1).png";
import flydoc2 from "../img/flydocx/fly(2).png";
import flydoc3 from "../img/flydocx/fly(3).png";
import flydoc4 from "../img/flydocx/fly(4).png";

const flydocx = () => {
  return (
    <>
      <Nav />

      <section className="flyBanner pt-5">
        <div className="container pt-sm-0">
          <div className="row mt-5">
            <div className="col-lg-6">
              <h1 className=" text-dark">
                Manage Your Travel Agency More Fluently <br /> with{" "}
                <span> </span>
                <span className="product-name-text">FlyDocx</span>
              </h1>

              <h6 className="text-dark">
                Manage all activitis of your Travel Agency including Customer,
                Agent, Vendor, Accounts and Staff Management. FlyDocx will store
                all of your Agency data for betterment of your Company.
              </h6>
              <br />
              <p className="p-0 m-0 text-secondary">Available For</p>
              <div className="d-flex mbApp">
                <div className="pe-3">
                  {" "}
                  <img src={google} alt="" />
                </div>
                <div>
                  {" "}
                  <img src={apple} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div className="docBan">
                <img src={banner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flyItem">
        <div className="container p-5 docitem">
          <h1 className="text-center mb-5"> Flawless Accounts Management</h1>
          <div className="row text-center">
            <div className="col-lg-3 box_shadow  mb-lg-0 mb-md-3 mb-5">
              <div className="p-5 box_2_shadow_1">
                <img src={flydoc1} alt="" /> <h5>Service Payment</h5>
                <p className="text-center">
                  Get flexible payment history of individual customer
                </p>
              </div>
            </div>
            <div className="col-lg-3   box_shadow  mb-lg-0 mb-md-3 mb-5">
              <div className="box_2_shadow_1 p-5">
                <img src={flydoc2} alt="" /> <h5> Vendor Payable</h5>
                <p className="text-center">
                  Make ensure transperncy between you and vendor
                </p>
              </div>
            </div>
            <div className="col-lg-3  box_shadow  mb-lg-0 mb-md-3 mb-5 ">
              <div className="p-5 box_2_shadow_1">
                <img src={flydoc3} alt="" /> <h5>Daily Expense</h5>
                <p className="text-center">
                  Record & Manage the daily expenses of your agency
                </p>
              </div>
            </div>
            <div className="col-lg-3  box_shadow  mb-lg-0 mb-md-3 mb-5">
              <div className="p-5 box_2_shadow_1">
                <img src={flydoc4} alt="" /> <h5>Business Report</h5>
                <p className="text-center">
                  Essential tool for agencies to assess perfomance and clearly
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default flydocx;
