import React from "react";
import Nav from "../components/nav";
import Footer from "../components/footer";
import apple from "../img/docAppoint/apple.png";
import google from "../img/docAppoint/google.png";
import banner from "../img/sowda/sowda.png";
import market from "../img/docAppoint/marketing-01.png";
import mobile from "../img/docAppoint/mobileapp-01.png";
import que from "../img/docAppoint/queue-01.png";

const enterprise = () => {
  return (
    <>
      <Nav />
      <section className="enterpriseBanner pt-5">
        <div className="container p-5 pt-sm-0">
          <div className="row">
            <div className="col-lg-12">
              <h2>
                Buy From Nearby Shop
                <br />
                More Easily With
              </h2>
              <h1 style={{ color: "#000000" }}>Enterprise</h1>

              <h6>
                Welcome to the country's only independent marketplace. Find your
                favorite store <br />
                Shop easily, hassle-free. Download Sauda App now without delay.
              </h6>
              <br />
              <p className="p-0 m-0">Available For</p>
              <div className="d-flex mbApp">
                <div className="pe-3">
                  {" "}
                  <img src={google} alt="" />
                </div>
                <div>
                  {" "}
                  <img src={apple} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="position-relative container">
          <div className="position-absolute bottom-0 end-0 position_1">
            <div className="docBan">
              <img src={banner} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container p-5 docitem">
          <h1 className="text-center mb-5">
            {" "}
            Sowda Will Be More Easy & More Fast
          </h1>
          <div className="row text-center">
            <div className="col-lg-3 box_shadow ">
              <img src={que} alt="" />{" "}
              <h5 style={{ color: "#FF4621" }}>Content Here</h5>
              <p className="text-center">
                The asaod asdjioad adiadiooa doiadiooa
              </p>
            </div>
            <div className="col-lg-3 box_shadow ">
              <img src={market} alt="" />{" "}
              <h5 style={{ color: "#FF4621" }}>Content Here</h5>
              <p className="text-center">
                The asaod asdjioad adiadiooa doiadiooa
              </p>
            </div>
            <div className="col-lg-3 box_shadow ">
              <img src={mobile} alt="" />{" "}
              <h5 style={{ color: "#FF4621" }}>Content Here</h5>
              <p className="text-center">
                The asaod asdjioad adiadiooa doiadiooa
              </p>
            </div>
            <div className="col-lg-3 box_shadow ">
              <img src={que} alt="" />{" "}
              <h5 style={{ color: "#FF4621" }}>Content Here</h5>
              <p className="text-center">
                The asaod asdjioad adiadiooa doiadiooa
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default enterprise;
