import logo from "./logo.svg";
import "./App.css";
import { Link, Route, Routes } from "react-router-dom";
import NotFoundPage from "./page/NotFoundPage";
import Home from "./page/home";
import Enterprise from "./page/enterprise";
import Docappoint from "./page/docappoint";
import Flydocx from "./page/flydocx";
import Schoolsys from "./page/schoolsys";
import Client from "./page/clients";
import About from "./page/aboutUs";
import Product from "./page/products";
import Embedded from "./page/embedded";
import Cloud from "./page/cloud";
import Design from "./page/design";
import Contact from "./page/contact";
import Career from "./page/career";
import Digital from "./page/Digital";
import Someekoron from "./page/Someekoron";
import SingleBlogView from "./page/blogView";
import AllBlogView from "./page/allBlogView";
import Vacancies from "./page/vacancies";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/" element={<Home />} />
        <Route path="/docappoint" element={<Docappoint />} />
        <Route path="/enterprise" element={<Enterprise />} />
        <Route path="/flydocx" element={<Flydocx />} />
        <Route path="/schoolsys" element={<Schoolsys />} />
        <Route path="/aboutUs" element={<About />} />
        <Route path="/clients" element={<Client />} />
        <Route path="/products" element={<Product />} />
        <Route path="/embedded" element={<Embedded />} />
        <Route path="/cloud" element={<Cloud />} />
        <Route path="/design" element={<Design />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/career" element={<Career />} />
        <Route path="/Digital" element={<Digital />} />
        <Route path="/someekoron" element={<Someekoron />} />
        <Route path="/blog/:id" element={<SingleBlogView />} />
        <Route path="/allBlogView" element={<AllBlogView />} />
        <Route path="/vacancies/:id" element={<Vacancies />} />
      </Routes>
    </div>
  );
}

export default App;
